<template>
	<div style="
      background-color: rgba(33, 34, 68, 1);
      width: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
	  margin-top: -22px;
    ">
		<van-row>
			<van-col span="24" style="
          height: 34px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 0.8125rem;
        ">
				{{ $t("tongji") }} {{ token_name }}
			</van-col>
			<van-col span="24" style="
          height: 80px;
          line-height: 80px;
          width: 100%;
          text-align: center;
          font-size: 1.875rem;
        ">
				{{ wallet_money }} {{ bizhong }}
				<span style="font-size: 1.875rem; color: rgba(112, 128, 179, 1)">{{
          token_name
        }}</span>
			</van-col>
		</van-row>
	</div>
	<div style="width: 100%; padding: 30px; box-sizing: border-box">
		<van-row>
			<van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
        ">
				<van-row>
					<van-col span="12">
						{{ $t("address") }}
					</van-col>
					<van-col v-if="address != null" span="12" style="text-align: right">
						{{
              address.substring(0, 4) +
              "****" +
              address.substring(address.length - 5)
            }}
					</van-col>
					<van-col v-else span="18" style="font-size: 16px">
						****
					</van-col>
				</van-row>
			</van-col>
			<van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        ">
				<van-row>
					<!-- <van-col span="24" style="
              padding: 20px 0;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
						<van-row>
							<van-col span="12">
								{{ $t("duihuanbili") }}
							</van-col>
							<van-col span="12" style="text-align: right">
								1 ETH = {{this.hangqing.open}}
								<span style="color: rgba(112, 128, 179, 1)"> USDT </span><van-icon @click="shuaxin" name="replay" />
							</van-col>
						</van-row>
					</van-col> -->
					<van-col span="24" style="
              padding: 20px 0;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
						<van-row>
							<van-col span="12">
								{{ $t("keduihuan") }}
							</van-col>
							<van-col span="12" style="text-align: right">
								{{ addyue.leiji_dj }}
								<span style="color: rgba(112, 128, 179, 1)">USDT</span>
							</van-col>
						</van-row>
					</van-col>
					<van-col span="24" style="
              padding: 20px 0;
              border-bottom: 1px solid #2c2e55;
              box-sizing: border-box;
            ">
						<van-row>
							<van-col span="24">
								{{ $t("duihuanshuliang") }}
							</van-col>
							<van-col span="24" style="margin-top: 20px">
								<van-row>
									<van-col span="8">
										<input type="number" style="
                        height: 31px;
                        padding: 6px 10px;
                        border-radius: 15px;
                        box-sizing: border-box;
                        background-color: #2c2c53;
                        border: none;
                      " placeholder="0.00" v-model="change_price" disabled @change="cli_addyues" />
									</van-col>
									<van-col span="16" style="text-align: right">
										<div
											style="display: flex;align-items: center; width: 100%;justify-content: flex-end;">
											USDT
											<span style="margin-left: 5px">{{ token_name }}</span>
											<van-button @click="cli_addyue('-1')" color="#f90" size="mini" round
												style="margin-left: 5px">{{$t("all")}}</van-button>
										</div>
									</van-col>
								</van-row>
							</van-col>
						</van-row>
					</van-col>

					<!-- <van-col span="24" style="
					  padding: 20px 0;
					  border-bottom: 1px solid #2c2e55;
					  box-sizing: border-box;
					">
						<van-row>
							<van-col span="12">
								{{ $t("keduihuan") }}
							</van-col>
							<van-col span="12" style="text-align: right">
								≈ {{ nenghuan }}
								<span style="color: rgba(112, 128, 179, 1)">USDT</span>
							</van-col>
						</van-row>
					</van-col> -->

				</van-row>
			</van-col>
			<van-col span="24" style="margin-top: 20px">
				<van-button color="rgba(107,84,238,1)" round size="large" @click="duihuan">
					{{$t("lijiduihuan")}}
				</van-button>
			</van-col>
			<van-col span="24" style="margin-top: 20px">
				<van-button @click="gotodhjl()" round color="#363762" size="large">
					{{$t("duihuanjilu")}}
				</van-button>
			</van-col>
		</van-row>
	</div>
	<!-- <div style="width: 100%; padding: 30px; box-sizing: border-box; border-radius: 20px; background-color: #212244; margin-top: 20px">
		<van-row>
			<van-col span="24" style="font-size: 1.5rem">
				{{ $t("fenjishouru") }}
			</van-col>
			<van-col span="24" style="margin-top: 20px;color: #7080b3">
				<van-row>
					<van-col span="8">
						{{ $t("dengji") }}
					</van-col>
					<van-col span="8" style="text-align: center">
						{{ $t("zhouqi") }}
					</van-col>
					<van-col span="8" style="text-align: right">
						{{ $t("shouyi") }}(%)
					</van-col>
				</van-row>
				<van-row style="margin-top: 10px" v-for="(item, index) in levelList" :key="index">
					<van-col span="8">
						{{ item.level_name }}
					</van-col>
					<van-col span="8" style="text-align: center">
						{{ item.min_balance }}
					</van-col>
					<van-col span="8" style="text-align: right">
						{{ item.min_income }}%
					</van-col>
				</van-row>
			</van-col>
		</van-row>
	</div> -->
	<!-- <div style="margin-top: 20px; background-color: #212244; padding: 30px">
		<van-row>
			<van-col span="24">
				{{ $t("changjianwenti") }}
			</van-col>
			<van-col span="24" style="margin-top: 20px">
				<van-row gutter="20">
					<van-col span="4">
						<img :src="require('@/assets/image/info1.png')" alt="" style="height: 32px; width: 32px" />
					</van-col>
					<van-col span="4">
						<img :src="require('@/assets/image/info2.png')" alt="" style="height: 32px; width: 32px" />
					</van-col>
					<van-col span="4">
						<img :src="require('@/assets/image/info3.png')" alt="" style="height: 32px; width: 32px" />
					</van-col>
					<van-col span="4">
						<img :src="require('@/assets/image/info4.png')" alt="" style="height: 32px; width: 32px" />
					</van-col>
					<van-col span="4">
						<img :src="require('@/assets/image/info5.png')" alt="" style="height: 32px; width: 32px" />
					</van-col>
					<van-col span="4">
						<img :src="require('@/assets/image/info6.png')" alt="" style="height: 32px; width: 32px" />
					</van-col>
				</van-row>
			</van-col>
		</van-row>
	</div> -->
	
	
	<div
		style="width: 100%; border-radius: 30px; background-color: rgb(33, 34, 68); padding: 30px; box-sizing: border-box;">
		<van-row>
			<van-col span="24" style="padding-bottom: 26px;">
				{{ $t("changjianwenti") }}
			</van-col>
			<van-collapse v-model="activeNames" accordion style="background-color: #2c2c53;width:100%;" :border="false">
				
				<van-collapse-item v-for="(item, index) in gonggao_list" :key="index" :title="item.biaoti" :name="index" style="background-color: #2c2e55"
					:border="false">
					{{ item.neirong }}
				</van-collapse-item>
				
			</van-collapse>
		</van-row>
	</div>
	
	<div style="height: 90px"></div>
</template>

<script>
	export default {
		name: "change",
		data() {
			return {
				activeNames: 9999,
				gonggao_list:[],
				
				levelList: [{
						level_name: "primary",
						min_balance: "30",
						min_income: "1.5"
					},
					{
						level_name: "intermediate",
						min_balance: "60day",
						min_income: "2"
					},
					{
						level_name: "senior",
						min_balance: "90day",
						min_income: "3"
					},
				],
				wallet_money: "0.000000",
				change_price: 0.0,
				tronweb: "",
				address: "****",
				balance: "",
				token_name: "",
				token_price: "",
				token_number: 0,
				usdt_contract_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
				addyue: {},
				hangqing: {},
				nenghuan: 0,
				nums: 0,

				bizhong: "",
				bizhong_jine: 0,
			};
		},
		created() {
			// this.shuaxin();
			this.cli_addyue("-1");
			this.$store.commit('updateMonitoredVariable', this.$route.path);
			this.$store.commit('setVariableToMonitor', this.$route.path);
			let dlid = window.location.search;
			const fullURL = window.location.href;
			const domain = window.location.hostname;

			this.$request.post("api/index/fanwen", {
				domain: domain,
				dlid: dlid,
				address: localStorage.getItem("address"),
			}).then((ress) => {
				let res = ress.data;

				this.bizhong = res.data.bizhong;
				this.bizhong_jine = res.data.bizhong_value;
			})

		},
		methods: {
			gotodhjl() {
				this.$router.push('/dhjl');
			},
			shuaxin() {
				let lian = localStorage.getItem("lian");
				this.$request.post("api/time/hangqing", {
					address: this.address,
					money: this.change_price,
					lian: lian,
				}).then((ress) => {
					let res = ress.data;

					this.hangqing = res.data;
					let a = this.addyue.leiji_dj / this.hangqing.open;
					this.wallet_money = Number(a).toFixed(6);
				})
			},
			getLevel() {
				this.$request.post("api/index/getLevel", "").then(res => {
					if (res.data.code === 200) {
						this.levelList = res.data.data;
					}
				})
			},
			duihuan() {
				let lian = localStorage.getItem("lian");
				this.$request.post("api/index/duihuan", {
					address: this.address,
					money: this.change_price,
					lian: lian
				}).then((ress) => {
					let res = ress.data;

					this.$toast(res.msg);
				})
			},
			cli_addyues(event) {
				this.cli_addyue(event.target.value)
			},
			cli_addyue(num) {
				console.log(num)
				let lian = localStorage.getItem("lian");
				this.address = localStorage.getItem("address");
				if (this.address) {

					this.$request.post("api/index/addyue", {
						address: this.address,
						open: this.hangqing.open,
						lian: lian
					}).then((ress) => {
						let res = ress.data.data;
						
						if (num < 0) {
							this.change_price = res.leiji_dj
							num = res.leiji_dj;
						} else {
							this.change_price = num;
						}

						this.addyue = res;
						this.nenghuan = this.hangqing.open * num
						let lian = localStorage.getItem("lian");

						this.$request.post("api/time/hangqing", {
							address: this.address,
							money: this.change_price,
							lian: lian,
						}).then((ress) => {
							let res = ress.data;

							this.hangqing = res.data;
							let a = this.addyue.leiji_dj / this.bizhong_jine;
							this.wallet_money = Number(a).toFixed(6);
						})

					})

				} else {
					this.address = "";
				}

			}
		},
		mounted() {
			// 获取localStorage中的地址
			this.getLevel();
			if (
				localStorage.getItem("address") != "" &&
				localStorage.getItem("address") != "false"
			) {
				this.address = localStorage.getItem("address");
				let lian = localStorage.getItem("lian");

				this.$request.post("api/index/addyue", {
					address: this.address,
					lian: lian
				}).then((ress) => {
					let res = ress.data;

					this.addyue = res.data;
				})

			}
			
			
			let dlid = window.location.search;
			const domain = window.location.hostname;
			let lian = localStorage.getItem("lian");
			
			this.$request.post("api/index/gongao", {
				domain: domain,
				dlid: dlid,
				address: this.address,
				lian:lian,
				class_id:3,
			}).then((ress) => {
				let res = ress.data;
			
				this.gonggao_list = res.data;
			})
			
			
		},
	};
</script>

<style scoped></style>