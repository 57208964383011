import {
	createRouter,
	createWebHistory,
	createWebHashHistory,
} from "vue-router";
import HomeView from "../views/HomeView.vue";
import Change from "@/views/change";
import Service from "@/views/service";
import My from "@/views/my";
import Invite from "@/views/invite";
import Myteam from "@/views/myteam";
import Dhjl from "@/views/dhjl";
import Txjl from "@/views/txjl";
import Mytxjl from "@/views/mytxjl";
import Mysy from "@/views/mysy";
import Gonggao from "@/views/gonggao";
import Gonggaoshow from "@/views/gonggaoshow";

const routes = [{
		path: "/",
		name: "home",
		component: HomeView,
		//设置默认页面为home
		//redirect: "/home"
	},
	{
		path: "/about",
		name: "about",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () =>
			import( /* webpackChunkName: "about" */ "../views/AboutView.vue"),
	},
	{
		path: "/change",
		name: "change",
		component: Change,
	},
	{
		path: "/service",
		name: "service",
		component: Service,
	},
	{
		path: "/my",
		name: "my",
		component: My,
	},
	{
		path: "/myteam",
		name: "myteam",
		component: Myteam,
	},
	{
		path: "/dhjl",
		name: "dhjl",
		component: Dhjl,
	},
	{
		path: "/txjl",
		name: "txjl",
		component: Txjl,
	},
	{
		path: "/mytxjl",
		name: "mytxjl",
		component: Mytxjl,
	},
	{
		path: "/mysy",
		name: "mysy",
		component: Mysy,
	},
	{
		path: "/invite",
		component: Invite,
	},
	{
		path: "/gonggao",
		name: "gonggao",
		component: Gonggao,
	},
	{
		path: "/gonggaoshow",
		name: "gonggaoshow",
		component: Gonggaoshow,
	},
];

const router = createRouter({
	mode: "history",
	history: createWebHashHistory(process.env.BASE_URL),
	routes,
});

export default router;