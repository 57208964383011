<template>
	<div style="
	display: none;
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #212244;
    ">
		<van-row align="center">
			<van-col span="6">
				<img :src="require('@/assets/image/logo.png')" alt=""
					style="width: 64px; height: 64px; border-radius: 10px" />
			</van-col>
			<van-col v-if="address != null" span="18" style="font-size: 16px">
				{{
          address.substring(0, 4) +
            "****" +
            address.substring(address.length - 5)
        }}
			</van-col>
			<van-col v-else span="18" style="font-size: 16px">
				****
			</van-col>
		</van-row>
	</div>
	<!-- <div style="padding: 4% 6% 0 6%; box-sizing: border-box">
		<van-row style="padding: 15px; border-radius: 10px; background-color: #212244">
			<van-col span="12"> {{ $t("tibiqianbao") }} </van-col>
			<van-col span="12" style="text-align: right">
				{{
          address.substring(0, 4) +
            "****" +
            address.substring(address.length - 5)
        }}
			</van-col>
		</van-row>
	</div> -->

	<div
		style="width: 100%; padding: 30px; box-sizing: border-box; border-radius: 20px; background-color: #212244; margin-top: 20px;margin-bottom: 90px;margin-top:-14px">
		<van-col span="24" style="font-size: 1.5rem">
			{{ $t("duihuanjilu") }}
		</van-col>
		<van-row style='line-height: 36px;'>
			<van-col span="12">{{$t('riqi')}}</van-col>
			<van-col style="text-align: right;" span="12">{{$t('duihuanshuliang')}}</van-col>
		</van-row>
		<van-row v-for="(index,key) in wk_config" style='line-height: 36px;'>
			<van-col span="12">{{index.createTime}}</van-col>
			<van-col style="text-align: right;" span="12">{{index.balance}} USDT</van-col>
		</van-row>
	</div>


</template>

<script>
	export default {
		name: "dhjl",
		data() {
			return {
				change_price: 0,
				token_number: 0,
				address: "",
				show: false,
				isshow: false,
				money: "",
				finalRecorder: [],
				addyue: {},
				recorde: [],
				daican: "",
				wk_config: [],
			};
		},
		methods: {
			getWkConfig() {
				let lian = localStorage.getItem("lian");
				this.$request.post("api/index/dhjl", {
					address: this.address,
					lian:lian
				}).then((ress) => {
					let res = ress.data;
				
					this.wk_config = res.data;
				})
				
			},
			goto() {
				if (this.address == "") {
					this.$toast(this.$t('ljqb'));
					return;
				}
				this.$router.push('/invite')
			},
			dhjl() {
				if (this.address == "") {
					this.$toast(this.$t('ljqb'));
					return;
				}
				this.show = !this.show;
			},
			wdsy() {
				if (this.address == "") {
					this.$toast(this.$t('ljqb'));
					return;
				}
				this.isshow = !this.isshow;
			},
			getMoney() {
				if (this.address) {
					this.$request
						.post("api/index/getMoney", {
							address: this.address
						})
						.then((res) => {
							if (res.data.code === 200) {
								this.token_number = res.data.data.number;
							}
						});
				}
			},
			async getTrcWallet() {
				this.tronweb = window.tronWeb;
				this.address = this.tronweb.defaultAddress.base58;
			},
			// 记录
			Record() {
				this.address = localStorage.getItem("address");
				let lian = localStorage.getItem("lian");
				//获取余额
				this.$request.post("api/index/addyue", {
					address: this.address,
					lian: lian
				}).then((ress) => {
					let res = ress.data.data;
				
					this.addyue = res;
					
					if (res.yue == "1") {
						this.daican = res.balance * 1.5 / 30;
					} else if (res.yue == "2") {
						this.daican = res.balance * 1.5 / 60;
					} else if (res.yue == "3") {
						this.daican = res.balance * 1.5 / 90;
					}
					
				})
				//我的收益
				this.$request.post("api/index/recorder", {
					address: this.address,
					lian: lian
				}).then((ress) => {
					let res = ress.data;
				
					this.recorde = res.data;
				})
				
			},
			//兑换
			exchange() {
				if (this.address == "") {
					this.$toast(this.$t('ljqb'));
					return;
				}
				// if (this.money == "") {
				// 	this.$toast("请输入提现金额");
				// 	return;
				// }
				this.$request.post("api/index/tiqu", {
					address: this.address,
					lian: lian,
					money: this.money,
				}).then((ress) => {
					let res = ress.data;
				
					this.$toast('success')
				})
				
			},
		},
		mounted() {
			this.$store.commit('setVariableToMonitor', this.$route.path);
			
			// let lian = localStorage.setItem("lian","trc20");
			if (localStorage.getItem("address") != "" && localStorage.getItem("address") != "false") {
				this.address = localStorage.getItem("address");
			}
			this.getWkConfig();
			// this.getTrcWallet();
			// this.getMoney();

			this.Record();
		},
		computed: {

		}
	};
</script>

<style scoped>
	.mydunhuan {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(34, 32, 32, 0.5);
	}

	.myjl {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		height: 40%;
		background-color: #fff;
		border-radius: 20px;
		background-color: rgba(34, 32, 32, 0.8);
		border-radius: 20px !important;
		/* border: 1px solid #fff; */
		overflow-y: auto;
		-ms-overflow-style: none;
		--tw-gradient-from: #000;
		--tw-gradient-stops: var(--tw-gradient-from), #000, rgba(9, 147, 236, 0));
		--tw-gradient-from: #000;
		--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
		--tw-gradient-to: #000;
		background-image: linear-gradient(90deg, var(--tw-gradient-stops));
	}

	.my_dhjl {
		display: flex;
		/* justify-content: center; */
		align-items: center;
	}

	.my_dhjl h2 {
		display: flex;
		/* justify-content: center; */
		align-items: center;
		font-size: 18px;
		margin-left: 10px;
	}

	.my_dhjl span {
		/* margin-right: 10px; */
		margin-left: auto;
		padding: 20px;
	}

	.myyj {
		height: 80px;
		background-color: #202231;
		margin: 10px;
		display: flex;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.my_bz {
		width: 50%;
		/* height: 100%; */
	}

	.my_lx {
		width: 43%;
		display: flex;
		background: #23c68b33;
		color: #21c187;
		border-radius: 25px;
	}

	.my_lx span {
		/* margin-left: auto; */
		display: block;
		/* text-align: center; */
		margin: 10px auto;
		/* padding-right: 20px; */
	}
</style>