import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		monitoredVariable: "",
		gonggaoVariable:"",
		variableToMonitor: '',
		addyue: {}
	},
	getters: {},
	mutations: {
		updateMonitoredVariable(state, newValue) {
			state.monitoredVariable = newValue;
		},
		updateMonitoredVariable(state, newValue) {
			state.gonggaoVariable = newValue;
		},
		setVariableToMonitor(state, newValue) {
			state.variableToMonitor = newValue
		},
		setAddyueMonitor(state, newValue) {
			state.addyue = newValue
		}
	},
	actions: {},
	modules: {}
})