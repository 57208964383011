import {
	createApp
} from "vue";
import Vuex from 'vuex';

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vant from "vant";
import {
	NavBar
} from 'vant';
import "vant/lib/index.css";
import 'vant/es/dialog/style';

import request from "@/config/request";
import Api from "@/config/api";
import {
	createI18n
} from "vue-i18n";
import zh from "@/i18n/lang/zh.json";
import en from "@/i18n/lang/en.json";
import jp from "@/i18n/lang/jp.json";
import ru from "@/i18n/lang/ru.json";
import kor from "@/i18n/lang/kor.json";
import sp from "@/i18n/lang/sp.json";
import fa from "@/i18n/lang/fa.json";
import ta from "@/i18n/lang/ta.json";
import pu from "@/i18n/lang/pu.json";
import ge from "@/i18n/lang/ge.json";
import tu from "@/i18n/lang/tu.json";
import sa from "@/i18n/lang/sa.json";
import ie from "@/i18n/lang/ie.json";
import es from "@/i18n/lang/es.json";
import it from "@/i18n/lang/it.json";
import nl from "@/i18n/lang/nl.json";
import gre from "@/i18n/lang/gre.json";

let lang = localStorage.getItem("lang") ?? "en";
const i18n = new createI18n({
	locale: lang,
	messages: {
		en: en,
		zh: zh,
		jp: jp,
		ru: ru,
		kor: kor,
		sp: sp,
		fa: fa,
		ta: ta,
		pu: pu,
		ge: ge,
		tu: tu,
		sa: sa,
		ie: ie,
		es: es,
		it: it,
		nl: nl,
		gre: gre
	},
});

const app = createApp(App);
app.config.globalProperties.$request = request;
app.config.globalProperties.$api = Api;
app.use(store).use(Vuex).use(router).use(vant).use(i18n).use(NavBar).mount("#app");

/*实时获取sessionStorage的值*/