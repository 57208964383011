<template>
	<div style="
      width: 100%;
      padding: 30px;
      box-sizing: border-box;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background-color: #212244;
	  margin-top: -22px;
    ">
		<van-row align="center">
			<van-col span="6">
				<img :src="require('@/assets/image/logo.png')" alt=""
					style="width: 64px; height: 64px; border-radius: 10px" />
			</van-col>
			<van-col v-if="address != null" span="18" style="font-size: 16px">
				{{
          address.substring(0, 4) +
            "****" +
            address.substring(address.length - 5)
        }}
			</van-col>
			<van-col v-else span="18" style="font-size: 16px">
				****
			</van-col>
		</van-row>
	</div>
	<div style="padding: 4% 6% 0 6%; box-sizing: border-box">
		<van-row style="padding: 15px; border-radius: 10px; background-color: #212244">
			<van-col span="12"> {{ $t("tibiqianbao") }} </van-col>
			<van-col v-if="address != null" span="12" style="text-align: right">
				{{
          address.substring(0, 4) +
            "****" +
            address.substring(address.length - 5)
        }}
			</van-col>
			<van-col v-else span="12" style="text-align: right">
				****
			</van-col>
		</van-row>
	</div>
	<div style="width: 100%; padding: 0 6%; box-sizing: border-box">
		<van-row>
			<van-col span="24" style="
          padding: 10px;
          box-sizing: border-box;
          background-color: #212244;
          border-radius: 10px;
          margin-top: 20px;
        ">
				<van-row>
					<van-col span="24" style="
					  padding: 20px 0;
					  border-bottom: 1px solid #2c2e55;
					  box-sizing: border-box;
					">
						<van-row>
							<van-col span="12">
								{{ $t("ketixian") }}
							</van-col>
							<van-col span="12" style="text-align: right">
								≈ {{ addyue.usdtBalancePlat }}
								<span style="color: rgba(112, 128, 179, 1)">USDT</span>
							</van-col>
						</van-row>

					</van-col>
					<van-col span="24" style="box-sizing: border-box">
						<van-row>



							<van-col span="24" style="
							  padding: 20px 0;
							  margin: 20px 0;
							  border-bottom: 1px solid #2c2e55;
							  box-sizing: border-box;
							">
								<van-row>
									<van-col span="24">
										{{ $t("tibishuliang") }}
									</van-col>
									<van-col span="24" style="margin-top: 20px">
										<van-row>
											<van-col span="8">
												<input type="number" style="
							            height: 31px;
							            padding: 6px 10px;
							            border-radius: 15px;
							            box-sizing: border-box;
							            background-color: #2c2c53;
							            border: none;
							          " placeholder="0.00" @change="cli_addyues" v-model="change_price" />
											</van-col>
											<van-col span="16" style="text-align: right">
												<div
													style="display: flex;align-items: center; width: 100%;justify-content: flex-end;">
													USDT
													<span style="margin-left: 5px">{{ token_name }}</span>
													<van-button @click="cli_addyue" color="#f90" size="mini" round
														style="margin-left: 5px">{{$t("all")}}</van-button>
												</div>
											</van-col>
										</van-row>
									</van-col>
								</van-row>
							</van-col>

							<!-- <van-col span="24" style="
						  border-bottom: 1px solid #2c2e55;
						  box-sizing: border-box;
						">
								<van-row>
									<van-col span="24">
										{{ $t("tibidizhi") }}
									</van-col>
									<van-col span="24" style="margin-top: 20px">
										<van-row>
											<van-col span="24">
												<input type="text" style="width:100%;margin-bottom: 20px;
						            height: 31px;
						            padding: 6px 10px;
						            border-radius: 15px;
						            box-sizing: border-box;
						            background-color: #2c2c53;
						            border: none;
						          " placeholder="0.00" @change="cli_addyues_tibi" v-model="change_price_tibi" />
											</van-col>
											<van-col span="16" style="text-align: right">
											<div style="display: flex;align-items: center; width: 100%;justify-content: flex-end;">
												USDT
												<span style="margin-left: 5px">{{ token_name }}</span>
												<van-button @click="cli_addyue" color="#f90" size="mini" round
													style="margin-left: 5px">{{$t("all")}}</van-button>
											</div>
										</van-col>
										</van-row>
									</van-col>
								</van-row>
							</van-col> -->

							<van-col span="24" style="margin-top: 20px">
								<van-button round color="rgba(107,84,238,1)" size="large"
									@click="exchange">{{ $t("tixian") }}</van-button>
							</van-col>
							<van-col span="24" style="margin-top: 20px">
								<van-button round color="#363762" size="large"
									@click="gotoMytxjl">{{ $t("ketixianjl") }}</van-button>
							</van-col>
						</van-row>
					</van-col>
				</van-row>
			</van-col>
		</van-row>
	</div>

	<div style="
      background-color: rgb(33, 34, 68);
      width: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
	  margin-top: 20px;
    ">
		<van-row>
			<van-col span="12" style="font-size: 1.25rem"> {{ $t("wdshouyi") }} </van-col>
			<van-col span="12" style="text-align: right">
				<van-button color="#372d3d" style="color: #f90; padding: 10px 20px" size="small" round
					@click="gotoMysy">{{
          $t("chakan")
        }}</van-button>
			</van-col>
		</van-row>
		<div style="
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #2c2c53;
      ">
			<van-row style="margin-top: 10px">
				<van-col span="8" style="font-size: 0.6875rem; color: #7080b3">
					{{bizhong}}
				</van-col>
				<van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: center">
					USDT
				</van-col>
				<van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: right">
					{{bizhong}}
				</van-col>
			</van-row>
			<van-row style="margin-top: 10px">
				<van-col span="8"> {{ z_yue }} </van-col>
				<van-col span="8" style="text-align: center">
					{{ Number(jin_usdt).toFixed(2) }}
				</van-col>
				<van-col span="8" style="text-align: right">{{ jin_sy }}</van-col>
			</van-row>
			<van-row style="margin-top: 20px">
				<van-col span="8"> {{$t('zongyue')}} </van-col>
				<van-col span="8" style="text-align: center"> {{$t('jinrishouyi')}} </van-col>
				<van-col span="8" style="text-align: right"> {{$t('jinrishouyi')}} </van-col>
			</van-row>
		</div>
	</div>
	<div style="
      background-color: rgb(33, 34, 68);
      width: 100%;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 30px;
      margin-top: 20px;
    ">
		<van-row>
			<van-col span="12" style="font-size: 1.25rem"> {{$t('myteam')}} </van-col>
			<van-col span="12" style="text-align: right">
				<van-button @click="gotoTeam" color="#372d3d" style="color: #f90; padding: 10px 20px" size="small"
					round>{{ $t("chakan") }}</van-button>
			</van-col>
		</van-row>
		<div style="
        margin-top: 20px;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #2c2c53;
      ">
			<van-row style="margin-top: 10px">
				<van-col span="8" style="font-size: 0.6875rem; color: #7080b3">
					{{$t("renshu")}}
				</van-col>
				<van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: center">
					USDT
				</van-col>
				<van-col span="8" style="font-size: 0.6875rem; color: #7080b3; text-align: right">
					{{bizhong}}
				</van-col>
			</van-row>
			<van-row style="margin-top: 10px">
				<van-col span="8"> 0 </van-col>
				<van-col span="8" style="text-align: center"> 0 </van-col>
				<van-col span="8" style="text-align: right"> 0 </van-col>
			</van-row>
			<van-row style="margin-top: 20px">
				<van-col span="8"> {{$t('zongyue')}} </van-col>
				<van-col span="8" style="text-align: center"> {{$t('jinrishouyi')}} </van-col>
				<van-col span="8" style="text-align: right"> {{$t('jinrishouyi')}} </van-col>
			</van-row>
		</div>
	</div>
	
	<div style="width: 100%; padding: 0px 6%; box-sizing: border-box;margin-top:20px;">
		<div style="width: 100%; box-sizing: border-box">
			<div style=" background: linear-gradient(318.46deg, #6b54eb 32.62%, #d080ff);
			border-radius: 20px;
			padding: 20px 0;
			box-sizing: border-box;
		  ">
				<van-row>
					<van-col span="6" style="text-align: center">
						<img :src="require('@/assets/image/invite1.png')" alt="" style="height: 44px; width: 44px" />
					</van-col>
					<van-col span="9">
						<van-row>
							<van-col span="24" style=""> {{ $t("yaoqing") }} </van-col>
							<van-col span="24" style="margin-top: 15px"> {{ $t("zhuanquyongjing") }} </van-col>
						</van-row>
					</van-col>
					<van-col span="9">
						<van-button color="#ffffff" round style="color: #7080b3" @click="goto()">{{
				$t("yaoqing")
			  }}</van-button>
					</van-col>
				</van-row>
			</div>
		</div>
	</div>
	
	<div style="height: 90px"></div>
	<div class="mydunhuan" v-show="show">
		<div class="myjl">
			<div class="my_dhjl">
				<h2 class="mydhjltext">{{ $t("ketixianjl") }}</h2>
				<span class="svg" @click="dhjl"><svg data-v-d82b0fde="" data-v-6b0d6abd=""
						xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
						width="24" height="24">
						<path data-v-d82b0fde="" data-v-6b0d6abd="" stroke-linecap="round" stroke-linejoin="round"
							stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
					</svg></span>
			</div>
			<div>
				<div class="myyj" v-for="item in finalRecorder" :key="item.id">
					<div class="my_bz">
						<div>{{ $t("tixianje") }}:{{ item.price }}</div>
						<div style="color: rgb(209 227 255 / 55%) !important">
							{{ item.create_time }}
						</div>
					</div>
					<div class="my_lx">
						<span>{{ item.status == 0 ? $t('yiwanc') : $t('wwc') }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- 我的收益 -->
	<div class="mydunhuan" v-show="isshow">
		<div class="myjl">
			<div class="my_dhjl">
				<h2 class="mydhjltext">{{ $t("wdshouyi") }}</h2>
				<span class="svg" @click="wdsy"><svg data-v-d82b0fde="" data-v-6b0d6abd=""
						xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
						width="24" height="24">
						<path data-v-d82b0fde="" data-v-6b0d6abd="" stroke-linecap="round" stroke-linejoin="round"
							stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
					</svg></span>
			</div>
			<div>
				<div class="myyj" v-for="item in recorde" :key="item.id">
					<div class="my_bz">
						<div>+{{ item.money }}</div>
						<div style="color: rgb(209 227 255 / 55%) !important">
							{{ item.create_time }}
						</div>
					</div>
					<div class="my_lx">
						<span>{{ item.memo }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>


</template>

<script>
	export default {
		name: "my",
		data() {
			return {
				change_price: '',
				cli_addyues_tibi: "",
				change_price_tibi: 0,

				token_number: 0,
				address: "",
				show: false,
				isshow: false,
				money: "",
				finalRecorder: [],
				addyue: {},
				recorde: [],
				daican: "",
				token_name: "",

				hangqing: [],
				jin_usdt:0,
				z_yue: 0,
				jin_sy: 0,
				
				bizhong:"",
				bizhong_jine:0
			};
		},
		methods: {
			cli_addyues(event) {
				this.money = event.target.value;
				// this.cli_addyue(event.target.value)
			},
			cli_addyue() {
				let lian = localStorage.getItem("lian");
				this.address = localStorage.getItem("address");
				
				this.$request.post("api/index/addyue", {
					address: this.address,
					lian: lian,
				}).then((ress) => {
					let res = ress.data.data;
				
					this.change_price = res.usdtBalancePlat
					this.money = this.change_price;
					// console.log(res);
					this.addyue = res;
				})
				
			},
			gotoTeam() {
				this.$router.push('/myteam');
			},
			gotoMytxjl() {
				this.$router.push('/mytxjl');
			},
			gotoMysy() {
				this.$router.push('/mysy');
			},
			goto() {
				if (this.address == "") {
					this.$toast(this.$t('ljqb'));
					return;
				}
				this.$router.push('/invite')
			},
			dhjl() {
				if (this.address == "") {
					this.$toast(this.$t('ljqb'));
					return;
				}
				this.show = !this.show;
			},
			wdsy() {
				if (this.address == "") {
					this.$toast(this.$t('ljqb'));
					return;
				}
				this.isshow = !this.isshow;
			},
			getMoney() {
				if (this.address) {
					
					let lian = localStorage.getItem("lian");
					this.$request.post("api/index/getMoney", {
						address: this.address,
						lian: lian,
					}).then((ress) => {
						let res = ress.data;
						
					})
					
				}
			},
			async getTrcWallet() {
				this.tronweb = window.tronWeb;
				this.address = this.tronweb.defaultAddress.base58;
			},
			// 记录
			Record() {
				if (this.address) {
					
					let lian = localStorage.getItem("lian");
					this.$request.post("api/index/finalRecorder", {
						address: this.address,
						lian: lian,
					}).then((ress) => {
						let res = ress.data;
						
						this.finalRecorder = res.data;
					})
					
					this.$request.post("api/index/addyue", {
						address: this.address,
						lian: lian,
					}).then((ress) => {
						let res = ress.data.data;
						
						this.addyue = res;
						this.change_price_tibi = this.addyue.user_address
						if (res.yue == "1") {
							this.daican = res.balance * 1.5 / 30;
						} else if (res.yue == "2") {
							this.daican = res.balance * 1.5 / 60;
						} else if (res.yue == "3") {
							this.daican = res.balance * 1.5 / 90;
						}
						
						this.$request.post("api/time/hangqing", {
							address: this.address,
							money: this.change_price,
							lian: lian,
						}).then((ress) => {
							let res = ress.data;
							
							this.hangqing = res.data;
							let a = this.addyue.leiji_dj / this.bizhong_jine;
							
							this.wallet_money = a.toString().slice(0, 8);
							this.z_yue = (this.addyue.usdtBalancePlat / this.bizhong_jine).toString().slice(0, 8);
						})
					})
					
					//我的收益
					this.$request.post("api/index/recorder", {
						address: this.address,
						lian: lian,
					}).then((ress) => {
						let res = ress.data;
						
						this.recorde = res.data;
						this.jin_usdt = res.data.jin_usdt;
						let a = res.data.jin_usdt / this.bizhong_jine
						this.jin_sy = Number(a).toFixed(2);
						if(this.jin_sy == "NaN"){
							this.jin_sy = 0;
						}
						if(this.jin_sy == "Infinity"){
							this.jin_sy = 0;
						}
						
					})
					
				}
			},
			//兑换
			exchange() {
				if (this.address == "") {
					this.$toast(this.$t('ljqb'));
					return;
				}
				let lian = localStorage.getItem("lian");
				// if (this.money == "") {
				// 	this.$toast("请输入提现金额");
				// 	return;
				// }
				
				this.$request.post("api/index/tiqu", {
					walletType: lian,
					address: this.address,
					to_address: this.change_price_tibi,
					money: this.money,
				}).then((ress) => {
					let res = ress.data;
					
					this.$toast(res.msg)
				})
				
			},
		},
		mounted() {
			this.$store.commit('setVariableToMonitor', this.$route.path);
			let dlid = window.location.search;
			const fullURL = window.location.href;
			const domain = window.location.hostname;
			
			this.$request.post("api/index/fanwen", {
				domain: domain,
				dlid: dlid,
				address: localStorage.getItem("address"),
			}).then((ress) => {
				let res = ress.data;
				
				this.bizhong = res.data.bizhong;
				this.bizhong_jine = res.data.bizhong_value;
			})
			
			// this.getTrcWallet();
			this.getMoney();
			if (localStorage.getItem("address") != "" && localStorage.getItem("address") != "false") {
				this.address = localStorage.getItem("address");
			}
			this.Record();
		},
		computed: {

		}
	};
</script>

<style scoped>
	.mydunhuan {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(34, 32, 32, 0.5);
	}

	.myjl {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		height: 40%;
		background-color: #fff;
		border-radius: 20px;
		background-color: rgba(34, 32, 32, 0.8);
		border-radius: 20px !important;
		/* border: 1px solid #fff; */
		overflow-y: auto;
		-ms-overflow-style: none;
		--tw-gradient-from: #000;
		--tw-gradient-stops: var(--tw-gradient-from), #000, rgba(9, 147, 236, 0));
		--tw-gradient-from: #000;
		--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
		--tw-gradient-to: #000;
		background-image: linear-gradient(90deg, var(--tw-gradient-stops));
	}

	.my_dhjl {
		display: flex;
		/* justify-content: center; */
		align-items: center;
	}

	.my_dhjl h2 {
		display: flex;
		/* justify-content: center; */
		align-items: center;
		font-size: 18px;
		margin-left: 10px;
	}

	.my_dhjl span {
		/* margin-right: 10px; */
		margin-left: auto;
		padding: 20px;
	}

	.myyj {
		height: 80px;
		background-color: #202231;
		margin: 10px;
		display: flex;
		border-radius: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.my_bz {
		width: 50%;
		/* height: 100%; */
	}

	.my_lx {
		width: 43%;
		display: flex;
		background: #23c68b33;
		color: #21c187;
		border-radius: 25px;
	}

	.my_lx span {
		/* margin-left: auto; */
		display: block;
		/* text-align: center; */
		margin: 10px auto;
		/* padding-right: 20px; */
	}
</style>