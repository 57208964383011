import Axios from "axios";
import api from "./api";

// 引入加密库
import CryptoJS from 'crypto-js';

// 加密函数
function encryptData(data, key) {
	const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
	return encrypted;
}
// 解密函数
function decryptData(encryptedData) {
	let key = CryptoJS.enc.Utf8.parse("1572329129539WZH");//十六位十六进制数作为密钥，可自行设置
	let iv = CryptoJS.enc.Utf8.parse("ZZWBKJ_ZHIHUAWEI");//十六位十六进制数作为密钥偏移量，可自行设置
	let str = encryptedData; // 前面加密生成的数据
	var decrypted = CryptoJS.AES.decrypt(str, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7
	});
	let res = JSON.parse(JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted)));
	return res;
}

const instance = Axios.create({
	baseURL: api.api,
	timeout: 100000
});

// let lang = localStorage.getItem("lang") ?? "en";

instance.interceptors.request.use(config => {
	config.headers["token"] = sessionStorage.getItem("token") ? sessionStorage.getItem("token") : 'miaolun'
	// config.headers["language"] = sessionStorage.getItem("language") ? sessionStorage.getItem("language") : 'en'
	config.headers["language"] = localStorage.getItem("lang") ? localStorage.getItem("lang") : 'en'
	return config;
}, error => {
	return Promise.reject(error);
})

instance.interceptors.response.use(response => {
	// console.log(response.data.data);
	// 在这里对返回的数据进行解密操作，并将解密后的数据返回
	response.data.data = decryptData(response.data.data); // 使用你的解密函数对response.data进行解密
	return response;
}, error => {
	return Promise.reject(error);
});
export default instance;